import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import React, {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useState
} from "react";
import { useQuery } from "react-query";
import { fetchArticle } from "../../clients/GeneratedKnowledge/fetchArticle";
import styles from "./EditArticleModal.module.scss";

interface EditArticleModalProps {
  articleId: string;
  handleClose: () => void;
  handleSave: ({ title, body }: { title: string; body: string }) => void;
}

const EditArticleModal = forwardRef<
  HTMLDivElement,
  PropsWithChildren<EditArticleModalProps>
>(({ articleId, handleClose, handleSave }, ref) => {
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");

  const articleQuery = useQuery(
    ["Article", articleId],
    () => fetchArticle({ articleId }),
    {}
  );
  const isLoading = articleQuery.isLoading;
  const article = articleQuery?.data;

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value);
  };

  const handleBodyChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setBody(e.target.value);
  };

  const handleSaveChanges = (): void => {
    handleSave({ title, body });
  };

  useEffect(() => {
    if (article) {
      setTitle(article.title);
      setBody(article.body.body);
    }
  }, [article]);

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.contentContainer}>
        <div className={styles.modalHeader}>
          <h3>Edit Article</h3>
          <button onClick={handleClose}>
            <Icons.Times size="lg" />
          </button>
        </div>
        {isLoading && (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )}
        {article && (
          <>
            <div className={styles.articleEditingContainer}>
              <div className={styles.titleInputContainer}>
                <input defaultValue={title} onChange={handleTitleChange} />
              </div>
              <div className={styles.bodyTextareaContainer}>
                <textarea defaultValue={body} onChange={handleBodyChange} />
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <button
                onClick={handleSaveChanges}
                className={styles.saveArticleButton}
              >
                <Icons.Save />
                Save changes
              </button>
              <button onClick={handleClose} className={styles.discardButton}>
                <Icons.Trash />
                Discard changes
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export { EditArticleModal };
