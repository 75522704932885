import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { GeneratedKnowledgeListData } from "@aptedge/lib-ui/src/types/entities";
import { isEmpty } from "lodash";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

const PER_PAGE = 10;

interface FetchArticlesArgs {
  perPage?: number;
  page?: number;
  limit?: number;
  filters?: IQueryFilter[];
  ticketId?: string;
  include?: string;
}

function fetchArticles({
  perPage = PER_PAGE,
  page = 1,
  limit = 500,
  filters,
  ticketId,
  include
}: FetchArticlesArgs): Promise<GeneratedKnowledgeListData> {
  let url = `${getBackendApiUrl()}/generated_knowledge/?per-page=${perPage}&page=${page}&limit=${limit}`;
  if (!isEmpty(filters)) {
    url += `&filters=${JSON.stringify(filters)}`;
  }
  if (!isEmpty(ticketId)) {
    url += `&ticket_id=${ticketId}`;
  }
  if (!isEmpty(include)) {
    url += `&include=${include}`;
  }
  return request(url);
}

export { fetchArticles };
