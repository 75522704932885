import {
  AptAnswersFilter,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";

type Args = {
  filtersData: AptAnswersFilter[];
  integrationsData: { [key: string]: string[] };
};

export function mergeFiltersWithIntegrations({
  filtersData,
  integrationsData
}: Args): AptAnswersIntegrationsObject[] {
  const integrations: AptAnswersIntegrationsObject[] = [];

  for (const key in integrationsData) {
    const integration: AptAnswersIntegrationsObject = {
      name: key,
      fields: integrationsData[key],
      filter: undefined
    };

    const filter: AptAnswersFilter | undefined = filtersData.find((filter) => {
      const filterRules = filter.filterRules;
      if (filterRules.length) {
        const filterRule = filterRules[0];
        if (filterRule.integration === integration.name) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    });

    integration.filter = filter;

    if (integration.fields.length > 0) {
      integrations.push(integration);
    }
  }

  return integrations;
}
