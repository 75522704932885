enum FontSize {
  MINI = "10px",
  XSMALL = "12px",
  SMALL = "13px",
  REGULAR = "14px",
  MEDIUM = "16px",
  LARGE = "18px",
  XLARGE = "24px",
  TITLE = "32px"
}

enum FontFamily {
  GOTHIC = "Gothic A1"
}

enum FontWeight {
  THIN = 100,
  EXTRA_LIGHT = 200,
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
  BLACK = 900
}

export { FontFamily, FontSize, FontWeight };
