import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  AptAnswersFilterPurpose,
  AptAnswersFilterRule
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterHeaderMessage.module.scss";

interface RulesBadgeProps {
  rulesLength: number;
}

const RulesBadge: React.FC<RulesBadgeProps> = ({ rulesLength }) => {
  return (
    <div className={styles.rulesBadgeContainer}>{`${rulesLength} ${
      rulesLength === 1 ? "rule" : "rules"
    }`}</div>
  );
};

interface HiddenBadgeProps {
  hiddenIsDefault: boolean;
}

const HiddenBadge: React.FC<HiddenBadgeProps> = ({ hiddenIsDefault }) => {
  return (
    <div
      className={
        hiddenIsDefault
          ? styles.hiddenTextContainer
          : styles.hiddenBadgeContainer
      }
    >
      <Icons.EyeSlash />
      Hidden
    </div>
  );
};

interface FilterHeaderMessageProps {
  filterPurpose: AptAnswersFilterPurpose;
  rules: AptAnswersFilterRule[];
}

const FilterHeaderMessage: React.FC<FilterHeaderMessageProps> = ({
  filterPurpose,
  rules
}) => {
  let message;
  const hasRules = rules.length > 0;
  const manageAllSelected = hasRules && rules[0].fieldsStr === "all-fields";

  if (!hasRules) {
    message =
      filterPurpose === AptAnswersFilterPurpose.SHOW ? (
        <HiddenBadge hiddenIsDefault={true} />
      ) : (
        <p className={styles.message}>Showing All Results</p>
      );
  } else if (manageAllSelected) {
    message =
      filterPurpose === AptAnswersFilterPurpose.HIDE ? (
        <HiddenBadge hiddenIsDefault={false} />
      ) : (
        <p className={styles.message}>Showing All Results</p>
      );
  } else {
    message = <RulesBadge rulesLength={rules.length} />;
  }

  return <div className={styles.container}>{message}</div>;
};

export { FilterHeaderMessage };
