import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import {
  ModalFooter,
  ModalWidth
} from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import { ISlackChannel } from "@aptedge/lib-ui/src/types/entities";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import classNames from "classnames";
import React, { useState, ReactNode } from "react";
import { useMutation } from "react-query";
import { updateSlackChannels } from "../../../clients/SocialSource/updateSlackChannels";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { ClientStatus } from "../../../types/clients";
import "./SlackChannelList.scss";

interface Props {
  channelList: ISlackChannel[];
  className?: string;
  onChange: () => void;
}

const SlackChannelList: React.FC<Props> = (props: Props) => {
  const { channelList, className, onChange } = props;
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [channelRemove, setChannelRemove] = useState<ISlackChannel | null>(
    null
  );
  const [status, setStatus] = React.useState(ClientStatus.PROMPT);

  const handleDeleteClick = (channel: ISlackChannel): void => {
    setChannelRemove(channel);
    setShowConfirm(true);
  };

  const handleCancelDeleteModal = (): void => {
    setChannelRemove(null);
    setShowConfirm(false);
  };

  const updateSource = useMutation(updateSlackChannels, {
    onError: () => {
      setChannelRemove(null);
      setShowConfirm(false);
      setStatus(ClientStatus.PROMPT);
    },
    onSuccess: () => {
      setChannelRemove(null);
      setShowConfirm(false);
      setStatus(ClientStatus.PROMPT);
      onChange();
    }
  });

  const removeChannelFromList = (): void => {
    const index = channelList.findIndex(
      (item) => item?.channelId === channelRemove?.channelId
    );
    if (channelRemove?.channelId === channelList[index].channelId) {
      const tempList = channelList?.map(
        (channel: ISlackChannel) => channel.channelId
      );
      tempList.splice(index, 1);
      updateSource.mutate({ channels: tempList });
    }
  };

  const renderChannelItem = (item: ISlackChannel, index: number): ReactNode => {
    return (
      <div
        key={`${item?.channelId}_${index}`}
        className="mt-2 mb-4 d-flex justify-content-between align-items-center slack-list-border"
      >
        <div className="slack-link-icon col-md-1">
          <AeIcons.Link />
        </div>
        <div className="text-dark col-md-3">{`ID: ${item?.channelId}`}</div>
        <div className="text-dark col-md-4">{`#${item.channelName}`}</div>
        <div className="d-flex justify-content-left align-items-center col-md-2">
          <AeIcons.Lock />
          <span className="text-dark ml-2">
            {item.isPrivate ? "Private" : "Public"}
          </span>
        </div>
        <div
          data-testid={`delete-channel-${item?.channelId}`}
          className="col-md-1 cursor-pointer align-items-center justify-content-end"
          onClick={() => handleDeleteClick(item)}
        >
          <Icon icon={faTrashAlt} />
        </div>
      </div>
    );
  };

  return (
    <>
      {channelList && channelList.length ? (
        <div
          className={classNames("custom-field-list-item", "group", className)}
        >
          {channelList?.map((channel: ISlackChannel, index: number) =>
            renderChannelItem(channel, index)
          )}
        </div>
      ) : (
        <p className="badge text-muted">No channel added</p>
      )}
      <ConfirmationModal
        title={`Confirmation Modal`}
        width={ModalWidth.MEDIUM}
        isOpen={showConfirm}
        status={status}
        loadingTitle="Loading..."
        onClose={handleCancelDeleteModal}
      >
        <p>{`Are you sure you want to remove this channel.`}</p>
        <p>{`Channel ID:    ${channelRemove?.channelId}`}</p>
        <p>{`Channel Name:  ${channelRemove?.channelName}`}</p>
        <p></p>

        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button
                  color="secondary"
                  onClick={() => handleCancelDeleteModal()}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="delete button"
                  color="danger"
                  onClick={removeChannelFromList}
                >
                  Delete
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
    </>
  );
};

export { SlackChannelList };
