import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  ArticleUser,
  updateArticleCreatedUser,
  updateArticleReviewedUser,
  updateArticleReviewers,
  updateArticleStatus,
  updateAvailablePublicly,
  updateSelectedReviewer
} from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleAvailablePublicly,
  ArticleReviewStatus,
  ArticleReviewer,
  ArticleReviewerList,
  ArticleStatus,
  ArticleStatusBtn,
  GeneratedKnowledgeResult,
  IUserListing
} from "@aptedge/lib-ui/src/types/entities";
import { RefObject, useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { updateReviewStatus } from "../clients/GeneratedKnowledge/updateReviewStatus";
import { ARTICLE_STATUS_BUTTON } from "../pages/ArticlesPage/ArticleDetails";

type UseArticleDetailsProps = {
  isOpen: boolean;
  availablePublicly: ArticleAvailablePublicly;
  ticketId?: string | number;
  externalId?: string | number;
  statusBtn: ArticleStatusBtn[];
  containerRef: RefObject<HTMLDivElement>;
  selectedArticleStatus?: ArticleStatusBtn;
  articleReviewers: ArticleReviewerList;
  selectedReviewer: IUserListing;
  isReviewerExist?: ArticleReviewer;
  handleShowArticleStatus: () => void;
  handleArticleStatus: (articleStatus: ArticleStatus) => void;
  handleCheckbox: () => void;
  handleReviewStatus: (index: number) => void;
  handleRemoveReviewer: (index: number) => void;
  handleUserSearchClear: (articleUser: string) => void;
  handleAddReviewer: () => void;
  handleLink: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

function useArticleDetails(
  article: GeneratedKnowledgeResult
): UseArticleDetailsProps {
  const containerRef = useRef<HTMLDivElement>(null);
  const { flags } = useFlags();
  const { kbGenPublishProcess } = flags;
  const [isOpen, setIsOpen] = useState(false);
  const [statusBtn, setStatusBtn] = useState(ARTICLE_STATUS_BUTTON);
  const [selectedArticleStatus, setSelectedArticleStatus] = useState<
    ArticleStatusBtn
  >();

  const { ticket_id: ticketId, external_id: externalId } =
    article?.ticketIds[0] || {};

  const articleStatusAndIcon =
    ARTICLE_STATUS_BUTTON.find(
      (btn) => btn.status.toLowerCase() === article?.status.toLowerCase()
    ) || ({} as ArticleStatusBtn);

  const {
    availablePublicly,
    articleReviewers,
    selectedReviewer
  } = useAppSelector((state) => state.knowledgeBase);

  const dispatch = useAppDispatch();

  const isReviewerExist = articleReviewers[article.id]?.find(
    (reviewer) => reviewer.reviewer_id === selectedReviewer.id
  );

  const updateReviewStatusMutation = useMutation(updateReviewStatus);

  const handleRemoveReviewer = (index: number): void => {
    const newArticleReviewers = {
      ...articleReviewers,
      [article.id]: [...articleReviewers[article.id]]
    };

    newArticleReviewers[article.id].splice(index, 1);
    dispatch(updateArticleReviewers(newArticleReviewers));
  };

  const handleAddReviewer = (): void => {
    if (isReviewerExist || !Boolean(selectedReviewer.email)) return;
    const newArticleReviewers = { ...articleReviewers };
    const newReviewer: ArticleReviewer = {
      reviewer_id: selectedReviewer.id,
      email: selectedReviewer.email,
      status: ArticleReviewStatus.PENDING,
      created_on: Date.now(),
      updated_on: Date.now()
    };
    newArticleReviewers[article.id] = [
      ...newArticleReviewers[article.id],
      newReviewer
    ];
    dispatch(updateArticleReviewers(newArticleReviewers));
    dispatch(updateSelectedReviewer({} as IUserListing));
  };

  const handleUserSearchClear = (articleUser: string): void => {
    if (articleUser === ArticleUser.CREATE) {
      dispatch(updateArticleCreatedUser({} as IUserListing));
    } else {
      dispatch(updateSelectedReviewer({} as IUserListing));
      dispatch(updateArticleReviewedUser({} as IUserListing));
    }
  };

  const handleCheckbox = (): void => {
    const newAvailablePublicly = {
      ...availablePublicly,
      [article.id]: !availablePublicly[article.id] ?? false
    };
    dispatch(updateAvailablePublicly(newAvailablePublicly));
  };

  const handleShowArticleStatus = (): void => {
    setIsOpen(!isOpen);
  };

  const handleArticleStatus = (articleStatus: ArticleStatus): void => {
    const newStatusBtn = [...statusBtn];
    newStatusBtn.map((btn) => {
      if (btn.status.toLowerCase() === articleStatus.toLowerCase()) {
        btn.selected = !btn.selected;
        setSelectedArticleStatus(btn);
        dispatch(updateArticleStatus(articleStatus));
      }
      return btn;
    });
    setStatusBtn(newStatusBtn);
    handleShowArticleStatus();
  };

  const handleReviewStatus = (index: number): void => {
    const newArticleReviewers = {
      ...articleReviewers,
      [article.id]: [...articleReviewers[article.id]]
    };

    newArticleReviewers[article.id][index] = {
      ...newArticleReviewers[article.id][index],
      status: ArticleReviewStatus.APPROVED
    };
    dispatch(updateArticleReviewers(newArticleReviewers));
    updateReviewStatusMutation.mutate({
      articleId: article.id,
      status: ArticleReviewStatus.APPROVED
    });
  };

  const handleLink = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
  };

  const handleOutsideClick = (): void => {
    setIsOpen(false);
  };

  useOnMouseDownOutside(containerRef, handleOutsideClick);

  useEffect(() => {
    if (article && kbGenPublishProcess) {
      setSelectedArticleStatus(articleStatusAndIcon);
      if (!articleReviewers[article.id]) {
        const newArticleReviewers = {
          ...articleReviewers,
          [article.id]: article.reviewers
        };
        dispatch(updateArticleReviewers(newArticleReviewers));
      }
      if (Object.keys(availablePublicly).length === 0) {
        const newArticleAvailablePublicly = {
          ...availablePublicly,
          [article.id]: article.public
        };
        dispatch(updateAvailablePublicly(newArticleAvailablePublicly));
      }
    }
  }, [
    article,
    kbGenPublishProcess,
    articleStatusAndIcon,
    dispatch,
    articleReviewers,
    availablePublicly
  ]);

  return {
    isOpen,
    availablePublicly,
    ticketId,
    externalId,
    containerRef,
    statusBtn,
    selectedArticleStatus,
    articleReviewers,
    selectedReviewer,
    isReviewerExist,
    handleShowArticleStatus,
    handleArticleStatus,
    handleCheckbox,
    handleReviewStatus,
    handleRemoveReviewer,
    handleUserSearchClear,
    handleAddReviewer,
    handleLink
  };
}

export { useArticleDetails };
