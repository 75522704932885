import merge from "lodash/merge";
import React, { createContext, useContext, useMemo } from "react";
import { useFlagOverrides } from "./FlagOverridesContext";
import { IFlagSet } from "./types";

// Create a new type for all keys, matching the exact value in LD.
export type FlagSet = {
  "jira-o-auth"?: boolean;
  demo?: boolean;
  customers?: boolean;
  "sentiment-score"?: boolean;
  recommendations?: boolean;
  "edge-resources"?: boolean;
  "edge-bulk-reply"?: boolean;
  "metrics-dashboard"?: boolean;
  "created-edges-dashboard"?: boolean;
  "created-tickets-dashboard-cube-js"?: boolean;
  "open-tickets-dashboard-cube-js"?: boolean;
  "suggested-kb-articles"?: boolean;
  "kb-articles-indicator"?: boolean;
  "release-aptedge-5760-edge-auto-response"?: boolean;
  "release-aptedge-6601-new-analytics-cube-js"?: boolean;
  "slack-composite-search"?: boolean;
  "zendesk-KB-Articles"?: boolean;
  "azure-devops-cloud"?: boolean;
  "salesforce-knowledge-articles"?: boolean;
  "notion-articles"?: boolean;
  "support-app-auto-search"?: boolean;
  "search-only-support-app"?: boolean;
  "google-oauth"?: boolean;
  "ms-oauth"?: boolean;
  "release-haystack-spike"?: boolean;
  "ms-teams"?: boolean;
  "answer-engine"?: boolean;
  sharepoint?: boolean;
  "sharepoint-drive"?: boolean;
  "answergpt-additional-ctx"?: boolean;
  "answergpt-model-settings-ui"?: boolean;
  "answer-engine-v2"?: boolean;
  "markdown-answers"?: boolean;
  "service-now-knowledge"?: boolean;
  "semantic-search"?: boolean;
  "analytics-tab"?: boolean;
  "invite-user"?: boolean;
  "answergpt-mode"?: "DEFAULT" | "MAGIC";
  "answergpt-DEFAULT-always-answer"?: boolean;
  "answergpt-MAGIC-always-answer"?: boolean;
  "answergpt-DEFAULT-streaming"?: boolean;
  "answergpt-MAGIC-streaming"?: boolean;
  "answergpt-additional-instructions-mode"?: string;
  "answergpt-chat-input-message"?: string;
  "answergpt-question-input-message"?: string;
  "answergpt-instructions-input-message"?: string;
  "insights-support-app-auto-ticket-summary"?: boolean;
  "insights-support-app-kb-draft"?: boolean;
  "insights-support-app"?: boolean;
  "self-service"?: boolean;
  "semantic-search-hybrid-max-federated-hits"?: number;
  "dynamic-integration"?: boolean;
  "css-analytics"?: boolean;
  "feedback-options"?: {
    "search-feedback-labels"?: string;
    "ticket-summary-feedback-labels"?: string;
    "kb-gen-feedback-labels"?: string;
    "answer-feedback-labels"?: string;
  };
  "insights-closed-ticket-statuses"?: string[];
  "css-config-page"?: boolean;
  "css-internal-config"?: boolean;
  "feedback-title"?: {
    "search-feedback-title"?: string;
    "ticket-summary-feedback-title"?: string;
    "kb-gen-feedback-title"?: string;
    "answer-feedback-title"?: string;
    "default-feedback-title"?: string;
  };
  "answergpt-ui-v2"?: boolean;
  "apt-answers-css-filters-ui"?: boolean;
  "apt-answers-hidden-filters-ui"?: boolean;
  "ticket-insight-v2"?: boolean;
  "kb-gen-publish-process"?: boolean;
};

const queryParams = new URLSearchParams(window.location.search);
const isServiceNow = !!queryParams.get("snowInstance");

const flagsStatusByEnvironment = (
  status: boolean,
  localEnvStatus = true
): boolean => {
  if (window.location.href.includes("localhost:")) return localEnvStatus;
  return status;
};

const flagsStatusByEnvironmentStr = (
  status: string,
  localEnvStatus?: string
): string => {
  if (localEnvStatus && window.location.href.includes("localhost:")) {
    return localEnvStatus ?? status;
  }
  return status;
};

const flagsStatusByEnvironmentStrArr = (
  status: string[],
  localEnvStatus?: string[]
): string[] => {
  if (localEnvStatus && window.location.href.includes("localhost:")) {
    return localEnvStatus ?? status;
  }
  return status;
};

/**
 * Function to ensure we always have a valid object with correct keys
 * in the UI just in case the schema changes on LD.com.
 *
 * New flags MUST be added here to be used in the app.
 */

function cleanseFlags(flags: FlagSet): IFlagSet {
  return {
    jiraOAuth: flags["jira-o-auth"] ?? flagsStatusByEnvironment(false),
    demo: flags.demo ?? flagsStatusByEnvironment(false),
    customers: flags.customers ?? flagsStatusByEnvironment(false),
    recommendations: flags.recommendations ?? true,
    edgeResources: flags["edge-resources"] ?? flagsStatusByEnvironment(false),
    edgeBulkReply: flags["edge-bulk-reply"] ?? flagsStatusByEnvironment(false),
    metricsDashboard:
      flags["metrics-dashboard"] ?? flagsStatusByEnvironment(false),
    createdTicketsDashboardCubeJs:
      flags["created-tickets-dashboard-cube-js"] ??
      flagsStatusByEnvironment(false),
    openTicketsDashboardCubeJs:
      flags["open-tickets-dashboard-cube-js"] ??
      flagsStatusByEnvironment(false),
    createdEdgesDashboard:
      flags["created-edges-dashboard"] ?? flagsStatusByEnvironment(false),
    edgeAutoResponse:
      flags["release-aptedge-5760-edge-auto-response"] ??
      flagsStatusByEnvironment(false),
    newAnalyticsTableFormat:
      flags["release-aptedge-6601-new-analytics-cube-js"] ??
      flagsStatusByEnvironment(false),
    slackConfig:
      flags["slack-composite-search"] ?? flagsStatusByEnvironment(false),
    zendeskKBArticles:
      flags["zendesk-KB-Articles"] ?? flagsStatusByEnvironment(false),
    azureDevopsCloud:
      flags["azure-devops-cloud"] ?? flagsStatusByEnvironment(false),
    salesforceKnowledgeArticles:
      flags["salesforce-knowledge-articles"] ?? flagsStatusByEnvironment(false),
    serviceNowKnowledge: flags["service-now-knowledge"] ?? false,
    msTeams: flags["ms-teams"] ?? flagsStatusByEnvironment(false),
    sharepoint: flags["sharepoint"] ?? flagsStatusByEnvironment(false),
    sharepointDrive:
      flags["sharepoint-drive"] ?? flagsStatusByEnvironment(false),
    answergptAdditionalCtx:
      flags["answergpt-additional-ctx"] ?? flagsStatusByEnvironment(false),
    answergptModelSettingsUI:
      flags["answergpt-model-settings-ui"] ?? flagsStatusByEnvironment(false),
    notionArticles: flags["notion-articles"] ?? flagsStatusByEnvironment(false),
    supportAppAutoSearch: flags["support-app-auto-search"] ?? true,
    googleOAuth: flags["google-oauth"] ?? flagsStatusByEnvironment(true),
    msOAuth: flags["ms-oauth"] ?? flagsStatusByEnvironment(true),
    searchOnlySupportApp: isServiceNow
      ? true
      : flags["search-only-support-app"] ??
        flagsStatusByEnvironment(false, false),
    releaseHaystackSpike:
      flags["release-haystack-spike"] ?? flagsStatusByEnvironment(false),
    answerEngine: flags["answer-engine"] ?? flagsStatusByEnvironment(false),
    answerEngineV2:
      flags["answer-engine-v2"] ?? flagsStatusByEnvironment(false),
    markdownAnswers:
      flags["markdown-answers"] ?? flagsStatusByEnvironment(false),
    inviteUser: flags["invite-user"] ?? flagsStatusByEnvironment(false),
    semanticSearch:
      flags["semantic-search"] ?? flagsStatusByEnvironment(false, false),
    alwaysAnswerGPT:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      flags[
        `answergpt-${flags["answergpt-mode"] ?? "DEFAULT"}-always-answer`
      ] ?? flagsStatusByEnvironment(false, false),
    streamingAnswers:
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      flags[`answergpt-${flags["answergpt-mode"] ?? "DEFAULT"}-streaming`] ??
      flagsStatusByEnvironment(false, false),
    answerGPTAdditionalInstructionsMode:
      flags["answergpt-additional-instructions-mode"] ??
      flagsStatusByEnvironmentStr("INSTRUCTIONS"),
    answerGPTChatInputMessage:
      flags["answergpt-chat-input-message"] ??
      flagsStatusByEnvironmentStr("Chat with Answer AI..."),
    answerGPTQuestionInputMessage:
      flags["answergpt-question-input-message"] ??
      flagsStatusByEnvironmentStr("Ask a follow-up question..."),
    answerGPTInstructionsInputMessage:
      flags["answergpt-instructions-input-message"] ??
      flagsStatusByEnvironmentStr("Additional instructions go here..."),
    analyticsTab: flags["analytics-tab"] ?? flagsStatusByEnvironment(false),
    sentimentScore: flags["sentiment-score"] ?? flagsStatusByEnvironment(false),
    ticketInsightV2:
      flags["ticket-insight-v2"] ?? flagsStatusByEnvironment(false),

    insightsAutoTicketSummary:
      flags["insights-support-app-auto-ticket-summary"] ??
      flagsStatusByEnvironment(false),
    insightsKBDraft:
      flags["insights-support-app-kb-draft"] ?? flagsStatusByEnvironment(false),
    insightsSupportApp:
      flags["insights-support-app"] ?? flagsStatusByEnvironment(false),
    selfService:
      flags["self-service"] ?? flagsStatusByEnvironment(false, false),
    cssConfigPage: flags["css-config-page"] ?? flagsStatusByEnvironment(false),
    federatedSearch:
      (flags["semantic-search-hybrid-max-federated-hits"] ?? 0) > 0,
    dynamicIntegration:
      flags["dynamic-integration"] ?? flagsStatusByEnvironment(false),
    cssAnalytics: flags["css-analytics"] ?? flagsStatusByEnvironment(false),
    cssInternalConfig:
      flags["css-internal-config"] ?? flagsStatusByEnvironment(false),
    feedbackOptions: {
      searchFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["search-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        ),
      ticketSummaryFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["ticket-summary-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        ),
      kbGenFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["kb-gen-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        ),
      answerFeedbackLabels:
        (flags["feedback-options"] &&
          flags["feedback-options"]["answer-feedback-labels"]) ??
        flagsStatusByEnvironmentStr(
          "Factually incorrect,No relevant results,Result for wrong product,Style/tone,Too long to respond,Other"
        )
    },
    insightsClosedTicketStatuses:
      flags["insights-closed-ticket-statuses"] ??
      flagsStatusByEnvironmentStrArr(
        ["closed", "solved"],
        ["closed", "solved"]
      ),
    feedbackTitle: {
      searchFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["search-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Is this result helpful?"),
      ticketSummaryFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["ticket-summary-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Is this summary helpful?"),
      kbGenFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["kb-gen-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Are these articles helpful?"),
      answerFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["answer-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Is this result helpful?"),
      defaultFeedbackTitle:
        (flags["feedback-title"] &&
          flags["feedback-title"]["default-feedback-title"]) ??
        flagsStatusByEnvironmentStr("Are these results helpful?")
    },
    answerGptUiV2:
      flags["answergpt-ui-v2"] ?? flagsStatusByEnvironment(false, false),
    aptAnswersCssFiltersUi:
      flags["apt-answers-css-filters-ui"] ?? flagsStatusByEnvironment(false),
    aptAnswersHiddenFiltersUi:
      flags["apt-answers-hidden-filters-ui"] ?? flagsStatusByEnvironment(false),
    kbGenPublishProcess:
      flags["kb-gen-publish-process"] ?? flagsStatusByEnvironment(false, false)
  };
}

type IContext = {
  flags: IFlagSet;
  isLoading: boolean;
};

const initialState: IContext = {
  flags: cleanseFlags({}),
  isLoading: true
};

const FlagsContext = createContext<IContext>(initialState);

interface Props {
  flags?: FlagSet;
  isLoading: boolean;
}

const FlagsProvider: React.FC<Props> = (props) => {
  const { flags, isLoading, children } = props;

  const [overrides] = useFlagOverrides();

  const resolvedState = useMemo(
    () => ({
      flags: merge({}, cleanseFlags(flags ?? {}), overrides),
      isLoading
    }),
    [overrides, flags, isLoading]
  );

  return (
    <FlagsContext.Provider value={resolvedState}>
      {children}
    </FlagsContext.Provider>
  );
};

const useFlags = (): IContext => useContext(FlagsContext);

const MockFlagsProvider: React.FunctionComponent<{
  flags: FlagSet;
}> = ({ flags, children }) => {
  const mockState = {
    ...initialState,
    flags: cleanseFlags(flags),
    isLoading: false
  };

  return (
    <FlagsContext.Provider value={mockState}>{children}</FlagsContext.Provider>
  );
};

export { FlagsProvider, MockFlagsProvider, useFlags };
