/* istanbul ignore file */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IRichTextContent } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import {
  EntityFilters,
  EntityType,
  IBusinessDataSource,
  IEdgeLink,
  IEdgeStatus,
  ILabel,
  IOriginalTimeSeriesData,
  SavedQueryType,
  ITheme,
  IUserListing,
  TicketSourceType,
  IBulkReplyFilters,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";

// Client state (useClient)
export enum ClientStatus {
  PROMPT = "PROMPT",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR"
}

export enum RequestMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE"
}
export const IS_FROM_APTEDGE = "is-from-aptedge";
export const OAUTH_REDIRECT_URL = "oauth-redirect-url";

export const MS_OAUTH_FLOW = "ms-oauth-flow";
export const GOOGLE_OAUTH_FLOW = "g-oauth-flow";
export interface IPaginationState {
  page: number;
  totalPages: number;
  total: number;
  perPage: number;
}

export interface IPaginatedResponse<T> extends IPaginationState {
  items: T;
  hasNext: boolean;
}

export interface ISortArg<T extends string> {
  id: T;
  desc: boolean;
}

export type IPaginatedRequest<T> = T & {
  /**
   * Any paginated listing will accept these GET vars which are used by backend to control pagination.
   */

  // String based sort (see: ISortArg)
  sortBy: string | null;
  // Maximum items per page.
  perPage: number;
  // Page number to get.  1-indexed.  That is the first page has index 1.
  page: number;
};

/**
 * Single source for shared client interfaces. Let's try to keep this file sorted
 * alphabetically by concern (e.g. Account, Business Data Source, etc).
 *
 *
 */

// Account
export interface IFetchAccountArgs {
  accountId: number;
}

export interface IFetchAccountResponse {
  id: number;
  name: string;
  onboardingComplete: boolean;
}

export interface IUpdatePasswordArgs {
  token: string;
  password: string;
}

// Business Data Source

export type ISaveBusinessDataSourceRequest = Pick<
  IBusinessDataSource,
  "providerUserLogin" | "oauthClientId" | "oauthClientSecret"
>;

export interface IUpdateBusinessDataSourceResponse {
  id: string;
}

type BasicEntityDataWhenWriting = { id: number };

// Customers
export interface IFetchCustomersArgs {
  name?: string;
  sortBy: string | null;
}

export interface IUpdateCustomerRequest {
  pinned: boolean;
}

// Edges
export interface IUpdateEdgeRequest {
  jiraKeys?: string[];
  status?: IEdgeStatus;
  labels?: Pick<ILabel, "id" | "type">[];
  richSummary?: IRichTextContent | null;
  archived?: boolean;
  assignee?: Pick<IUserListing, "id"> | null;
  links?: Omit<IEdgeLink, "urlReference">[];
  name?: string;
  teamId?: number | null;
  spaceName?: string | null;
  appendLabels?: boolean;
}

export interface IMergeEdgeArgs {
  edgeId: number;
  mergeSourceEdges: { id: number }[];
}

export interface IMigrateEdgeTicketsArgs {
  sourceEdge: { id: number };
  targetEdge: { id: number };
  tickets: { id: number }[];
}

export interface IFetchEdgeArgs {
  edgeId: number;
}

export interface IBulkReplyCreateRequest {
  filters: IBulkReplyFilters | undefined;
  content: string;
  /** Whether the reply should be internal or external */
  internal: boolean;
  ticketStatus?: { from: string; to: string };
}

// Emerging Trends
export interface IEmergingTrendsTimeSeriesResponse {
  timeSeries: IOriginalTimeSeriesData[];
  totalThisWeek: number;
  totalLastWeek: number;
  changeOverLastWeek: number;
}

export interface IEmergingTrendsSummaryData {
  id: number;
  name: string;
  type: EntityType;
  change: number;
  ticketCount: number;
}

export interface IEmergingTrendsSummaryResponse {
  topEntities: IEmergingTrendsSummaryData[];
}

// Features

export interface ICreateFeatureArgs {
  productId: number;
  name: string;
}

// "Labels"
export type ICreateLabelArgs =
  | { name: string; term: string }
  | { name: string; term: string; productId: number };

// Notifications
export type IFetchNotificationArgs = {
  newOnly?: boolean;
};

// Phrase

export interface ICreatePhraseArgs {
  ruleId: number;
  value: string;
}

// Products

export interface ICreateProductArgs {
  name: string;
}

// Product issues (top N)

export interface IFetchLinkedProductIssuesRequest extends EntityFilters {
  startTs?: number;
  endTs?: number;
  ticketPriorities: Array<string>;
  urlReferences: Array<number> | null;
  jiraIssueKeys: Array<string> | null;
  sortBy: string | null;
  topN?: number;
  textQuery: string | null;
}

// Themes

export interface ICreateThemeArgs {
  name: string;
}

export interface IUpdateThemeArgs {
  name?: string;
}

// Saved Queries
export interface ICreateSavedQueryArgs {
  name: string;
  type: SavedQueryType;
  query: string;
  edgeId?: number;
  reply?: string;
  replyDisabled?: boolean;
  replyInternal?: boolean;
}

export interface IFetchSavedQueryArgs {
  type: SavedQueryType[];
}

export interface IUpdateSavedQueryArgs {
  id: number;
  name?: string;
  query?: string;
  pinned?: boolean;
  reply?: string;
  replyDisabled?: boolean;
  replyInternal?: boolean;
}

// Tickets

export interface IFetchBaseTicketsArgs {
  products?: number[];
  features?: number[];
  themes?: number[];
  customers?: number[];
  ticketPriorities?: string[];
  jiraIssueKeys?: string[];
  textQuery?: string;
  closedTickets?: boolean;
}

export interface ITicketSourceRequest {
  type: TicketSourceType;
  instanceName?: string;
  oauthClientId?: string;
  oauthClientSecret?: string;
  providerUserLogin: string;
  fromBusinessDataSource?: number;
}

export interface ICreateCustomFieldMappingArgs {
  ticketSourceId?: string;
  fieldName: string;
  displayName: string;
  displayValue?: string;
}

export interface IFetchTicketArgs extends EntityFilters {
  daysFromNow: number | null;
  ticketPriorities: Array<string>;
  closedTickets: boolean | null;
  urlReferences: Array<number> | null;
  edge?: number;
  jiraIssueKeys: Array<string> | null;
  sortBy: string | null;
  textQuery: string | null;
}

export interface IFetchTicketsRequest
  extends Omit<IFetchTicketArgs, "daysFromNow"> {
  startTs: number;
  endTs: number;
}

export interface IUpdateTicketRequest {
  summary?: IRichTextContent;
  themes?: Pick<ITheme, "id">[];
}

// Ticket Counts
export interface IFetchTicketCountArgs extends EntityFilters {
  startTs?: number;
  endTs?: number;
  ticketPriorities: Array<string>;
  closedTickets: boolean | null;
  textQuery: string | null;
}

export interface IFetchLinkedIssueCountArgs extends EntityFilters {
  startTs?: number;
  endTs?: number;
  ticketPriorities: Array<string>;
  closedTickets: boolean | null;
  textQuery: string | null;
}

// User

export interface IUserCredentials {
  email: string;
  password: string;
}

export interface ILoginResponse {
  id: number;
  login: boolean;
  admin: boolean;
  accountName: string;
  onboardingComplete: boolean;
}

export interface IUpdateUserRequest {
  admin?: boolean;
  role?: UserRole;
}
