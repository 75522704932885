import {
  Loader,
  LoaderSize
} from "@aptedge/lib-ui/src/components/Loader/Loader";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import classNames from "classnames";
import { FC } from "react";
import Helmet from "react-helmet";
import {
  PaginationAlignment,
  PaginationBar
} from "../../components/PaginationBar/PaginationBar";
import { useArticleReview } from "../../hooks/useArticleReview";
import { ErrorMessage } from "../ArticlesPage/ErrorMessage";
import { ArticleItem } from "./ArticleItem";
import { ArticleItemV2 } from "./ArticleItemV2";
import styles from "./ArticlesReviewPage.module.scss";
import { EmptyState } from "./EmptyState";
import { Header } from "./Header";

const ArticlesReviewPage: FC = () => {
  const { flags } = useFlags();

  const {
    isArticleLoading,
    isKbDraftPreparing,
    showErrorMessage,
    showEmptyState,
    showArticleItems,
    articleList,
    handlePaginationChange,
    ticketId,
    totalPages,
    page
  } = useArticleReview();

  return (
    <>
      <Helmet>
        <title>Articles | Review Articles</title>
      </Helmet>
      <div className={styles.containerBackground}>
        <div
          className={classNames(styles.containerContent, {
            [styles.containerContentV2]: flags.kbGenPublishProcess
          })}
        >
          {isKbDraftPreparing ? (
            <div className={styles.articlePrepareloader}>
              <Loader size={LoaderSize.LARGE} />
              <p>Preparing linked articles</p>
            </div>
          ) : (
            <>
              <div className={styles.containerHeader}>
                <Header
                  ticketId={ticketId}
                  articlesLength={articleList.length ?? 3}
                />
              </div>
              <div className={styles.containerArticles}>
                {isArticleLoading && <Spinner />}
                {showErrorMessage && <ErrorMessage />}
                {showEmptyState && <EmptyState />}
                {showArticleItems &&
                  articleList?.map(
                    /* eslint-disable @typescript-eslint/no-explicit-any */
                    (article: any, idx: number) => {
                      return (
                        <div key={article.id}>
                          {flags.kbGenPublishProcess ? (
                            <ArticleItemV2
                              idx={idx}
                              article={article}
                              articlesLength={articleList.length ?? 0}
                            />
                          ) : (
                            <ArticleItem
                              idx={idx}
                              articlesLength={articleList.length ?? 0}
                              articleId={article.id}
                              ticketId={ticketId}
                            />
                          )}
                        </div>
                      );
                    }
                  )}
                {showArticleItems && flags.kbGenPublishProcess && (
                  <PaginationBar
                    page={page}
                    totalPages={totalPages || 0}
                    onChange={handlePaginationChange}
                    align={PaginationAlignment.CENTER}
                    className={styles.articlePagination}
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export { ArticlesReviewPage };
