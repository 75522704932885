import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { useHighlightByOffset } from "@aptedge/lib-ui/src/hooks/useHighlightByOffset";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateAnswer,
  updateAnswerAttempt,
  updateAnswerLoadingState,
  updateAnswerLoadingStartTimeMillis,
  updateAnswerCardVisibility,
  updateIsAnswerLoaded
} from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  setIsSearchLoading,
  updateSearchCardVisibility,
  updateSearchResultOffsets,
  updateSearchResults,
  updateSearchResultsExpanded,
  updateSearchResultsInfo,
  updateTotalSearchResults
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  GQADocSearchResult,
  ICompositeResult,
  QUERY_PARAMS,
  SearchFilterSubType,
  ISearchResultSortingModes,
  ISearchFilter
} from "@aptedge/lib-ui/src/types/entities";
import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { toUIErrorMessage } from "@aptedge/lib-ui/src/utils/errors";
import { dataLayerPush, GTM_EVENTS } from "@aptedge/lib-ui/src/utils/event";
import { generateTopSearchResults } from "@aptedge/lib-ui/src/utils/generator";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { UseInfiniteQueryResult, useInfiniteQuery } from "react-query";

export interface UseAiAnswerQueryReturnType {
  gptAnswer: UseInfiniteQueryResult<TimedResponse<GQADocSearchResult>>;
  refetchAnswer: () => void;
}

type makeAnswerAPICallArgs = {
  q: string;
  page: number;
  perPage: number;
  limit: number;
  sort: ISearchResultSortingModes;
  filter: ISearchFilter;
  filterSubType: SearchFilterSubType;
  format: string;
  additionalInstructions: string;
  answerId?: string;
  // the backend detects whether this field is set to true and samples all search requests in
  // Sentry
  firstRequest: boolean;
};

function triggerSearchEvent(
  response: TimedResponse<GQADocSearchResult>,
  searchQuery: string,
  activeSourceType: SearchFilterSubType,
  page: number,
  timers: object,
  autoSearchActive: boolean,
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false
): void {
  const { data, startTimeMillis, endTimeMillis } = response;
  const { items, total } = data;
  const event = autoSearchActive
    ? GTM_EVENTS.GTM_SUPPORT_AUTO_SEARCH
    : GTM_EVENTS.GTM_SEARCH;

  const loadingTimeSeconds = (endTimeMillis - startTimeMillis) / 1000;
  dataLayerPush({
    event,
    data: {
      search_query: searchQuery,
      search_context: activeSourceType,
      latency_seconds: loadingTimeSeconds,
      page: page,
      total_search_results: total,
      top_search_results: generateTopSearchResults(items),
      timers,
      is_proactive_answer: isProactiveAnswer,
      is_self_service_search: !!cssConfig && "proactive_answers" in cssConfig
    }
  });
}

const triggerQuestionAskedEvent = (
  searchQuery: string,
  answer: string,
  searchResults: ICompositeResult[],
  totalSearchResults: number,
  startTimeMillis: number | null,
  timers?: object
): void => {
  const sourcesIndex = answer.toLowerCase().indexOf("sources:");
  const loadingTimeSeconds = startTimeMillis
    ? (Date.now() - startTimeMillis.valueOf()) / 1000
    : null;

  dataLayerPush({
    event: GTM_EVENTS.GTM_QUESTION_ASKED,
    data: {
      answer_generated: answer,
      latency_seconds: loadingTimeSeconds,
      question: searchQuery,
      total_search_results: totalSearchResults,
      top_search_results: generateTopSearchResults(searchResults),
      sources:
        sourcesIndex !== -1
          ? answer.slice(sourcesIndex + "sources:".length).trim()
          : [],
      timers
    }
  });
};

const loadingIndicator = "⎢";

// WARNING: This function can only be safely invoked once per answer component. (All of our apps
// currently have one answer component, so this is fine.) Otherwise, multiple onSuccess() callbacks
// are registered to the same underlying query, which causes duplicate PostHog events to be
// triggered. The "right" way to do this *was* to register a global onSuccess() callback in
// QueryClient, but doing so doesn't invoke the callback for this query for some reason. Also,
// react-query has deprecated that global callback
// (https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose). So I think the way to fix it is
// to call useAiAnswerQuery() high enough in the component stack that we can simply pass its return
// values anywhere they're needed. That's basically what we have as of this writing, but future
// users should be warned that it is not safe to consider this a singleton.
const useAiAnswerQuery = (
  enabled: boolean,
  streamingAnswers: boolean,
  makeAnswerAPICall: (
    args: makeAnswerAPICallArgs
  ) => Promise<TimedResponse<GQADocSearchResult>>,
  cssConfig?: ConfigResponse,
  isProactiveAnswer = false
): UseAiAnswerQueryReturnType => {
  const dispatch = useAppDispatch();
  const { flags } = useFlags();
  const { removeQueryParams } = useQueryParams();

  const {
    aiModel,
    additionalInstruction,
    answerLoadingStartTimeMillis,
    answerAttempt
  } = useAppSelector((state) => state.answerGPT);

  const {
    searchQuery,
    searchFilter,
    searchFilterSubType,
    page,
    sortMode,
    searchResults,
    totalSearchResults,
    answerId,
    autoSearchActive
  } = useAppSelector((state) => state.search);

  const { searchArticle } = useAppSelector((state) => state.knowledgeBase);

  const { getHighlightedContentByOffset } = useHighlightByOffset();

  // TODO: useMemo() can technically expire its own cache, which would cause a duplicate query
  const cacheKey = [
    CACHE_KEY.COMPOSITE_SEARCH_AI,
    searchQuery,
    searchFilter,
    searchFilterSubType,
    sortMode,
    aiModel,
    additionalInstruction,
    answerAttempt,
    page,
    answerId,
    searchArticle
  ];

  // react-query requires a Boolean value for `enabled`, not just a falsy value
  const queryEnabled = Boolean(enabled && (searchQuery || searchArticle));
  const answerRequested = Boolean(answerId);
  const perPage = flags.answerGptUiV2 ? 30 : 10;

  const onSearchResultsReceived = (
    info: GQADocSearchResult,
    items: ICompositeResult[],
    total: number
  ): void => {
    dispatch(setIsSearchLoading(false));
    dispatch(updateSearchResultsInfo(info));
    dispatch(updateTotalSearchResults(total));

    const searchResultOffsets = items.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result: any) => result.offset
    );
    dispatch(updateSearchResultOffsets(searchResultOffsets));
    const newItems = items.map((item: ICompositeResult) =>
      getHighlightedContentByOffset(item)
    );
    dispatch(updateSearchResultsExpanded(newItems));
    dispatch(updateSearchResults(newItems));
  };

  const gptAnswer = useInfiniteQuery(
    cacheKey,
    ({ pageParam = 0 }) => {
      const searchParams = {
        q: searchArticle ? searchArticle : searchQuery,
        page: page,
        limit: 500,
        filter: searchArticle
          ? ISearchFilter.GENERATED_KNOWLEDGE
          : searchFilter,
        filterSubType: searchArticle ? "" : searchFilterSubType,
        perPage: perPage,
        sort: sortMode,
        format: aiModel,
        additionalInstructions: additionalInstruction,
        answerId: answerRequested ? answerId : undefined,
        firstRequest: searchArticle ? !!pageParam : !pageParam
      };

      if (!pageParam) {
        dispatch(setIsSearchLoading(true));
        dispatch(updateAnswer(null));
        dispatch(updateAnswerLoadingState(answerRequested));
        dispatch(updateIsAnswerLoaded(answerRequested));
        if (answerRequested) {
          dispatch(updateAnswerLoadingStartTimeMillis(Date.now()));
          dispatch(updateAnswerCardVisibility(true));
          dispatch(updateSearchCardVisibility(false));
          removeQueryParams(QUERY_PARAMS.RESULT_ID);
        }
      }

      return makeAnswerAPICall(searchParams);
    },
    {
      getNextPageParam: () => {
        return 1;
      },
      // react-query requires a Boolean value for `enabled`, not just a falsy value
      enabled: queryEnabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      // setting cacheTime and staleTime to infinity ensures that the query is not re-fetched when
      // the app is disabled and re-enabled, which happens when collapsing and expanding it in
      // the Zendesk or Salesforce UI
      cacheTime: Infinity,
      staleTime: Infinity,
      onError: (error) => {
        onSearchResultsReceived({} as GQADocSearchResult, [], 0);

        if (answerRequested) {
          dispatch(updateAnswer(toUIErrorMessage(error)));
          dispatch(updateAnswerLoadingState(false));
          dispatch(updateIsAnswerLoaded(true));
        }
      },
      onSuccess: (response) => {
        /*
          There are two response formats:
            * one-stage answer:
              {
                "answers": {
                  "detailed": {
                    "content": "<answer string>",
                    "done": <boolean>
                  }
                }
              }
            * two-stage answer:
              {
                "answers": {
                  "summary": {
                    "content": "<summary answer string>",
                    "done": <boolean>
                  },
                  "detailed": {
                    "content": "<detailed answer string>",
                    "done": <boolean>
                  }
                }
              }
        */
        const lastPage = response.pages[response.pages.length - 1];
        const {
          data: { answers, items, total, timers }
        } = lastPage;

        if (items !== undefined) {
          onSearchResultsReceived(lastPage.data, items, total);

          triggerSearchEvent(
            lastPage,
            searchQuery,
            searchFilterSubType,
            page,
            timers,
            autoSearchActive,
            cssConfig,
            isProactiveAnswer
          );
        }

        let displayAnswer = "";
        if (answers) {
          const { summary, detailed } = answers;
          let hasContent;
          if (summary) {
            // two-stage answer
            if (streamingAnswers || (summary.done && detailed.done)) {
              const summaryContent =
                summary.content + (summary.done ? "" : loadingIndicator);
              const detailedContent =
                detailed.content + (detailed.done ? "" : loadingIndicator);
              displayAnswer = `### Summary\n${summaryContent}\n### Details\n${detailedContent}`;
              hasContent = Boolean(summary.content || detailed.content);
            }
          } else {
            // one-stage answer
            if (streamingAnswers || detailed.done) {
              displayAnswer =
                detailed.content + (detailed.done ? "" : loadingIndicator);
              hasContent = Boolean(detailed.content);
            }
          }

          // don't show empty content
          if (hasContent) {
            dispatch(updateAnswer(displayAnswer));
            dispatch(updateAnswerLoadingState(false));
            dispatch(updateIsAnswerLoaded(false));
          }
        }

        if (answerRequested) {
          if (
            answers?.detailed?.done &&
            (!answers?.summary || answers?.summary.done)
          ) {
            triggerQuestionAskedEvent(
              searchQuery,
              displayAnswer,
              searchResults,
              totalSearchResults,
              answerLoadingStartTimeMillis,
              answers.detailed.timers
            );
            dispatch(updateIsAnswerLoaded(true));
          } else {
            setTimeout(() => {
              if (queryEnabled) {
                gptAnswer.fetchNextPage();
              }
            }, 200);
          }
        }
      }
    }
  );

  const refetchAnswer = (): void => {
    dispatch(updateAnswerAttempt(answerAttempt + 1));
  };

  return { gptAnswer, refetchAnswer };
};

export type { makeAnswerAPICallArgs };
export default useAiAnswerQuery;
