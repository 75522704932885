import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { useAptAnswersFilters } from "../../hooks/useAptAnswersFilters";
import { AptAnswersErrorMessage } from "./AptAnswersErrorMessage";
import { AptAnswersFilters } from "./AptAnswersFilters";
import styles from "./CustomerSelfServiceFilters.module.scss";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";

const CustomerSelfServiceFilters: React.FC = () => {
  const { filterType } = getAptAnswersFilterInfo(
    AptAnswersFilterType.CSS_FILTER
  );

  const { isError, isLoading, isSuccess } = useAptAnswersFilters(filterType);

  return (
    <div className={styles.container}>
      {isLoading && <Spinner />}
      {isError && (
        <AptAnswersErrorMessage message="There as an error loading Knowledge Sources." />
      )}
      {isSuccess && <AptAnswersFilters filterType={filterType} />}
    </div>
  );
};

export { CustomerSelfServiceFilters };
