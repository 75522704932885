import { getBackendUrl } from "../../../config";
import { request } from "../../../utils/request";
import { patch } from "../../utils/buildRequest";

async function updateMSTeamsConnection(args: {}): Promise<{}> {
  const url = `${getBackendUrl()}/api/integrations/msteams`;
  const options: RequestInit = patch(args);
  return request(url, options);
}

export { updateMSTeamsConnection };
