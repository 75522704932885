import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useRef } from "react";
import { IFlagSet } from "../../context/FlagsContext/types";
import { useAppSelector } from "../../redux/hook/hook";

interface UseAiAnswersReturnType {
  isAiSearchQuery: boolean;
  flags: IFlagSet;
  answerRef: React.RefObject<HTMLDivElement>;
  answerId: string;
}

const useAiAnswers = (): UseAiAnswersReturnType => {
  const answerRef = useRef<HTMLDivElement>(null);
  const { searchQuery, answerId } = useAppSelector((state) => state.search);
  const { flags } = useFlags();
  const isAiSearchQuery = Boolean(
    flags.alwaysAnswerGPT ? searchQuery : searchQuery?.endsWith("?")
  );

  return { isAiSearchQuery, flags, answerRef, answerId };
};

export default useAiAnswers;
