import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import RenderLink from "./RenderLink/RenderLink";
// We use `tsc` for linting. `tsc` has an infuriating limitation such that, under certain
// circumstances, certain third-party dependencies are checked by the linter and there is no way to
// disable it via configuration. (Even `skipLibCheck` and `exclude` do not disable this behavior.)
// For more, see https://github.com/microsoft/TypeScript/issues/41883.
//
// remark-gfm happens to have a nested dependency (`micromark-extension-gfm-strikethrough`) that is
// apparently misconfigured in a way that triggers this behavior _and_ it has linting errors,
// causing the `tsc` linter to fail for everything that imports this package. The only way out is
// to fool tsc's crawler by using `require()` instead of `import`.
//
// eslint-disable-next-line import/order
const remarkGfm = require("remark-gfm").default;

interface Props {
  answer: string;
  resultsMap: Map<string, ICompositeResult>;
  totalResults: number;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
}
const MarkdownAnswer: FC<Props> = ({
  answer,
  resultsMap,
  totalResults,
  client,
  aptEdgeBaseUrl
}) => {
  return (
    <ReactMarkdown
      // GitHub-flavored Markdown, which automatically converts URLs to links (among a few other
      // features).
      remarkPlugins={[remarkGfm]}
      // Note that Markdown technically allows HTML inline with Markdown markup. react-markdown
      // does not support this for security reasons, and its own AST generator without relying on
      // `dangerouslySetInnerHTML`. Therefore, HTML tags in the input are simply escaped using
      // React's native escaper. This makes it unnecessary to use rehype-sanitize, which would
      // remove those escaped tags and make it impossible for us to output code blocks containing
      // HTML, or even refer to HTML tags. For more, see
      // https://github.com/remarkjs/react-markdown/blob/main/changelog.md#remove-buggy-html-in-markdown-parser.
      components={{
        a: ({ href, children }) => (
          <RenderLink
            href={href as string}
            // props.children seems to always be a 0- or 1-element array of strings, but is
            // technically defined as an array of ReactElements. So do some extra checks and
            // conversions to ensure we always end up with a single string.
            content={
              children?.length > 0 && children[0] ? children[0].toString() : ""
            }
            resultsMap={resultsMap}
            totalResults={totalResults}
            client={client}
            aptEdgeBaseUrl={aptEdgeBaseUrl}
          />
        )
      }}
    >
      {answer}
    </ReactMarkdown>
  );
};

export default MarkdownAnswer;
