import { useSearchFilterData } from "@aptedge/lib-ui/src/features/SearchFilters/useSearchFilter";
import {
  AptAnswersFilterType,
  AptAnswersFilterPurpose,
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { createFilter } from "../../clients/AptAnswers/createFilter";
import { updateFilter } from "../../clients/AptAnswers/updateFilter";
import { WebCacheKey } from "../../clients/cache";
import { FilterActions } from "./FilterActions";
import { FilterDescription } from "./FilterDescription";
import { FilterHeader } from "./FilterHeader";
import { FilterRules } from "./FilterRules";
import styles from "./IntegrationItem.module.scss";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";
import { getIntegrationInfo } from "./utils/getIntegrationInfo";

interface IntegrationItemProps {
  filterType: AptAnswersFilterType;
  integration: AptAnswersIntegrationsObject;
  isTop: boolean;
  isBottom: boolean;
}

const IntegrationItem: React.FC<IntegrationItemProps> = ({
  filterType,
  integration,
  isTop,
  isBottom
}) => {
  const { filterPurpose, filterConditions } = getAptAnswersFilterInfo(
    filterType
  );

  const { searchFilterList } = useSearchFilterData();
  const integrationInfo = getIntegrationInfo(
    integration.name,
    searchFilterList
  );
  const integrationName = integrationInfo.label;
  const integrationIcon = integrationInfo.iconSrc;

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const handleExpandClick = (): void => {
    setIsExpanded(!isExpanded);
  };

  const [manageAllSelected, setManageAllSelected] = React.useState<boolean>(
    false
  );
  const [hasErrors, setHasErrors] = React.useState<boolean>(false);

  const hasExistingRules =
    !!integration?.filter?.id && integration?.filter?.filterRules.length > 0;
  const manageAllIsSaved =
    integration.filter?.filterRules?.[0].fieldsStr === "all-fields";
  const filterRules = integration?.filter?.filterRules;

  React.useEffect(() => {
    if (filterRules) {
      if (manageAllIsSaved) {
        setManageAllSelected(true);
        setRules([]);
      } else {
        setRules(filterRules);
      }
    }
  }, [filterRules, manageAllIsSaved]);

  const defaultRule = {
    integration: integration.name,
    fieldsStr: integration.fields[0],
    condition: filterConditions[0],
    value: ""
  };

  const [rules, setRules] = React.useState<AptAnswersFilterRule[]>([
    defaultRule
  ]);

  const handleAddRule = (): void => {
    setRules([...rules, defaultRule]);
  };

  const handleDeleteRule = (idx: number): void => {
    const newRules = rules.filter((_, ruleIdx) => idx !== ruleIdx);
    setRules(newRules);
  };

  const handleManageAllClick = (): void => {
    setManageAllSelected(!manageAllSelected);
  };

  const handleRuleUpdate = ({
    idx,
    key,
    value
  }: AptAnswersFilterRuleUpdate): void => {
    const newRules = [...rules];
    const rule = newRules[idx];
    rule[key] = value;
    setRules(newRules);
    const validRules = rules.every((rule) => rule.value.trim() !== "");
    if (validRules) {
      setHasErrors(false);
    }
  };

  const queryClient = useQueryClient();
  const resetOnMutation = React.useCallback(() => {
    queryClient.invalidateQueries([WebCacheKey.APT_ANSWERS_FILTERS]);
    setIsExpanded(false);
    if (manageAllSelected) {
      setRules([]);
    }
  }, [queryClient, manageAllSelected]);
  const createFilterMutation = useMutation(createFilter, {
    onSuccess: () => {
      resetOnMutation();
    }
  });
  const updateFilterMutation = useMutation(updateFilter, {
    onSuccess: () => {
      resetOnMutation();
    }
  });

  const filterId = integration?.filter?.id;
  const filterName = integration?.filter?.name ?? "";

  const saveManageAll = (): void => {
    const manageAllFilterRules = [
      {
        condition:
          filterPurpose === AptAnswersFilterPurpose.SHOW
            ? "show-everything"
            : "hide-everything",
        fieldsStr: "all-fields",
        integration: integration.name,
        value: ""
      }
    ];
    if (filterId) {
      updateFilterMutation.mutate({
        filterId,
        filterName,
        filterType,
        filterRules: manageAllFilterRules
      });
    } else {
      createFilterMutation.mutate({
        filterName,
        filterType,
        filterRules: manageAllFilterRules
      });
    }
  };

  const saveRules = (): void => {
    if (filterId) {
      updateFilterMutation.mutate({
        filterId: filterId,
        filterName: filterName,
        filterType,
        filterRules: rules
      });
    } else {
      createFilterMutation.mutate({
        filterName: "",
        filterType,
        filterRules: rules
      });
    }
  };

  const handleSave = (): void => {
    if (manageAllSelected) {
      return saveManageAll();
    }
    const validRules = rules.every((rule) => rule.value.trim() !== "");
    if (validRules) {
      saveRules();
    } else {
      setHasErrors(true);
    }
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerTop]: isTop,
        [styles.containerBottom]: isBottom
      })}
    >
      <FilterHeader
        integrationName={integrationName}
        integrationIcon={integrationIcon}
        filterPurpose={filterPurpose}
        rules={integration.filter?.filterRules}
        isExpanded={isExpanded}
        handleExpandClick={handleExpandClick}
      />
      {isExpanded && (
        <div className={styles.contentContainer}>
          <FilterDescription
            filterPurpose={filterPurpose}
            integrationName={integrationName}
          />
          <FilterRules
            rules={rules}
            fields={integration.fields}
            hasExistingRules={hasExistingRules}
            filterConditions={filterConditions}
            handleRuleUpdate={handleRuleUpdate}
            handleDeleteRule={handleDeleteRule}
            ruleEditIsDisabled={manageAllSelected}
            hasErrors={hasErrors}
          />
          <div className={styles.actionsContainer}>
            <FilterActions
              filterPurpose={filterPurpose}
              integrationName={integrationName}
              handleSave={handleSave}
              handleAddRule={handleAddRule}
              manageAllSelected={manageAllSelected}
              handleManageAllClick={handleManageAllClick}
              addRuleIsDisabled={manageAllSelected}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { IntegrationItem };
