import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import SkeletonLoader from "@aptedge/lib-ui/src/components/SkeletonLoader/SkeletonLoader";
import useAiAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/useAiAnswer";
import MarkdownAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/MarkdownAnswer";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswerCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { updateSearchCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { QUERY_PARAMS } from "@aptedge/lib-ui/src/types/entities";
import styled from "@emotion/styled";
import classNames from "classnames";
import styles from "./AnswerSearchResult.module.scss";

const AnswerSearchResultContainer = styled.div<{
  answerCardVisibility: boolean;
}>`
  display: flex;
  padding: 1rem;
  border-radius: 0.5rem;
  border: ${(props) =>
    props.answerCardVisibility ? "1px solid #D9F5F9" : "1px solid #d0d5dd"};
  flex-direction: column;
  font-family: "Inter";
  height: 10rem;
  overflow: hidden;
  background-color: ${(props) =>
    props.answerCardVisibility ? "#EEFDFF" : "#fff"};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 1rem;

  &:hover {
    background-color: #e1f8fc;
    border: 1px solid #aad5db;

    .title {
      color: #35a4b0;
    }
  }
`;

const AnswerTitle = styled.span`
  &.title {
    font-size: 24px;
    font-weight: 700;
    font-family: "Inter";
    color: #353d3f;
  }

  &.selectedTitle {
    color: #35a4b0;
  }
`;

const AnswerSearchResult: React.FC = () => {
  const { removeQueryParams } = useQueryParams();
  const { mappedResults, totalSearchResults } = useAiAnswer();
  const { isSearchLoading } = useAppSelector((state) => state.search);
  const {
    answer,
    answerCardVisibility,
    isAnswerLoaded,
    isAnswerLoading
  } = useAppSelector((state) => state.answerGPT);

  const dispatch = useAppDispatch();

  const showPreview = (): void => {
    dispatch(updateAnswerCardVisibility(true));
    dispatch(updateSearchCardVisibility(false));
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
  };

  const isLoadingState = isSearchLoading || isAnswerLoading || !isAnswerLoaded;

  return (
    <AnswerSearchResultContainer
      answerCardVisibility={answerCardVisibility}
      onClick={showPreview}
    >
      <div className={styles.answerHeader}>
        <AeIcons.AiAnswer />
        <AnswerTitle
          className={classNames("title", {
            selectedTitle: answerCardVisibility
          })}
        >
          Answer AI
        </AnswerTitle>
      </div>
      {isLoadingState ? (
        <SkeletonLoader lines={3} containerClassName={styles.loader} />
      ) : (
        <div className={styles.answerBody}>
          {answer && isAnswerLoaded && (
            <MarkdownAnswer
              resultsMap={mappedResults}
              totalResults={totalSearchResults}
              answer={answer}
            />
          )}
        </div>
      )}
    </AnswerSearchResultContainer>
  );
};

export { AnswerSearchResult };
