import React from "react";
import { HiddenFilters } from "../../../components/AptAnswers/HiddenFilters";

const Filters: React.FC = () => {
  return (
    <>
      <h3>Hidden Results</h3>
      <p>
        Never include information in answers and search if they meet any of the
        rules below.
      </p>
      <HiddenFilters />
    </>
  );
};

export { Filters };
