import classNames from "classnames";
import { FC } from "react";
import { AeIcons } from "../../components/Icon/AeIcons";
import { useSearchFeedback } from "../../hooks/useSearchFeedback";
import {
  APPROVAL_STATUS,
  FeedbackMessageState,
  SearchSource
} from "../../types/entities";
import { isWebApp } from "../../utils/event";
import styles from "./SearchFeedback.module.scss";

type FeedbackProps = {
  showFeedback: boolean;
  feedbackOptions: string;
  eventSource: string;
  searchSourceType?: string;
  feedbackId?: string;
};

export enum FeedbackSource {
  SEARCH = "search",
  TICKET_SUMMARY = "ticket_summary",
  KB_GEN = "kb_gen",
  ANSWERGPT = "answergpt"
}

const SearchFeedback: FC<FeedbackProps> = ({
  showFeedback,
  feedbackOptions,
  searchSourceType,
  eventSource,
  feedbackId
}) => {
  const {
    additionalFeedback,
    semanticSearch,
    answerGptUiV2,
    isVisible,
    feedbackMessageState,
    isFeedbackStateSubmitted,
    onFeedbackBtnClicked,
    handleSubmit,
    handleOptionClick,
    handleInputChange,
    isOptionSelected,
    getFeedbackText
  } = useSearchFeedback(showFeedback, eventSource, feedbackId);

  const isFeedbackContentSupportApp =
    !semanticSearch && searchSourceType === SearchSource.SEARCH && !isWebApp;

  const containerClassNames = classNames(styles.feedbackContent, {
    [styles.showFeedback]: isVisible,
    [styles.slideIn]: isVisible && !answerGptUiV2,
    [styles.showFeedbackTransition]: isVisible && !answerGptUiV2,
    [styles.feedbackContentTransition]: isVisible && !answerGptUiV2,
    [styles.feedbackContentSupportApp]: isFeedbackContentSupportApp,
    [styles.feedbackAlwaysVisible]: answerGptUiV2 && isWebApp
  });

  return (
    <>
      {((isVisible && !isWebApp) || !answerGptUiV2) && <hr />}
      <div className={containerClassNames} data-testid="feedback-container">
        <div
          className={classNames(styles.feedbackContainer, {
            [styles.feedbackContainerSupportApp]: !isWebApp,
            [styles.feedbackContainerSearchResult]: isWebApp && answerGptUiV2
          })}
        >
          {(feedbackMessageState === FeedbackMessageState.SUBMIT ||
            isFeedbackStateSubmitted()) && (
            <>
              <AeIcons.SubmitFeedback />
              <span>Thank you, your feedback makes AptEdge better!</span>
            </>
          )}
          {!isFeedbackStateSubmitted() &&
            (feedbackMessageState === FeedbackMessageState.APPROVAL ||
              feedbackMessageState === FeedbackMessageState.DISAPPROVAL ||
              feedbackMessageState === FeedbackMessageState.DEFAULT) && (
              <>
                <span data-testid="feedback-text">{getFeedbackText()}</span>
                <button
                  className={styles.positiveFeedbackButton}
                  onClick={() => onFeedbackBtnClicked(APPROVAL_STATUS.APPROVAL)}
                >
                  {feedbackMessageState === FeedbackMessageState.APPROVAL ? (
                    <AeIcons.ThumbsUpSelected />
                  ) : (
                    <AeIcons.ThumbsUp
                      className={styles.thumbsUpIcon}
                      data-testid="thumbs-up"
                    />
                  )}
                </button>
                <button
                  className={styles.negativeFeedbackButton}
                  onClick={() =>
                    onFeedbackBtnClicked(APPROVAL_STATUS.DISAPPROVAL)
                  }
                >
                  {feedbackMessageState === FeedbackMessageState.DISAPPROVAL ? (
                    <AeIcons.ThumbsDownSelected />
                  ) : (
                    <AeIcons.ThumbsDown
                      className={styles.thumbsDownIcon}
                      data-testid="thumbs-down"
                    />
                  )}
                </button>
              </>
            )}
          {feedbackMessageState === FeedbackMessageState.ADDITIONAL && (
            <div
              data-testid="feedback-labels"
              className={styles.feedbackLabels}
            >
              <span>Tell us what didn’t work</span>
              <div>
                {feedbackOptions.split(",").map((option, index) => (
                  <button
                    key={index}
                    onClick={() => handleOptionClick(option)}
                    className={classNames(styles.feedbackOption, {
                      [styles.optionSelected]: isOptionSelected(option),
                      [styles.feedbackOptionSupportApp]: !isWebApp
                    })}
                  >
                    {option}
                  </button>
                ))}
              </div>
              <div className={styles.submitFeedback}>
                <textarea
                  placeholder="Provide additional feedback"
                  className={classNames(styles.inputFeedback, {
                    [styles.inputFeedbackSupportApp]: !isWebApp
                  })}
                  value={additionalFeedback}
                  onChange={(e) => handleInputChange(e)}
                  inputMode="text"
                />
                <button
                  onClick={handleSubmit}
                  className={classNames(styles.submitButton, {
                    [styles.submitButtonSupportApp]: !isWebApp
                  })}
                  data-testid="submit-feedback"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {isVisible && eventSource === FeedbackSource.TICKET_SUMMARY && <hr />}
    </>
  );
};

export default SearchFeedback;
