import "./GlobalOAuth.scss";
import OAuthLogout from "../LoginPage/OAuthLogout";
import useOAuth from "./hooks/useOAuth";

const cardDetails: Record<string, string> = {
  title: "We couldn't find your primary email linked with an AptEdge account.",
  btnText: "Back to Login"
};
const GlobalOAuth: React.FC<{}> = () => {
  const { isExternal, statusCode } = useOAuth();

  if (isExternal) return <OAuthLogout />;
  return (
    <div className="auth-page-container">
      {statusCode !== 200 && (
        <div className="auth-card">
          <div className="title">{cardDetails.title}</div>
          <button className="back-btn" onClick={() => window.open("/")}>
            {cardDetails.btnText}
          </button>
        </div>
      )}
    </div>
  );
};

export default GlobalOAuth;
