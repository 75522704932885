import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Editor } from "codemirror";
import React from "react";
import { Dropdown } from "../Dropdown/Dropdown";
import { Icon } from "../Icon/Icon";
import { EditorUtils } from "../MarkdownEditor/EditorUtils";
import { MarkdownBlockType } from "../MarkdownEditor/types";

interface TextType {
  label: string;
  type: MarkdownBlockType;
  element: string;
}

const TEXT_TYPES: TextType[] = [
  { label: "Normal text", type: MarkdownBlockType.PARAGRAPH, element: "p" },
  { label: "Heading 1", type: MarkdownBlockType.HEADER_ONE, element: "h1" },
  { label: "Heading 2", type: MarkdownBlockType.HEADER_TWO, element: "h2" },
  { label: "Heading 3", type: MarkdownBlockType.HEADER_THREE, element: "h3" },
  { label: "Heading 4", type: MarkdownBlockType.HEADER_FOUR, element: "h4" },
  { label: "Heading 5", type: MarkdownBlockType.HEADER_FIVE, element: "h5" },
  { label: "Heading 6", type: MarkdownBlockType.HEADER_SIX, element: "h6" }
];

interface Props {
  className?: string;
  editor: Editor;
}

const MarkdownTextDropdown: React.FC<Props> = ({ editor, className }) => {
  return (
    <Dropdown.Container placement="bottom-start">
      <Dropdown.Toggle>
        <div
          className={classNames(
            "d-flex align-items-baseline justify-content-between",
            className
          )}
        >
          {TEXT_TYPES.find((t) => EditorUtils.isBlockActive(editor, t.type))
            ?.label || "Normal text"}
          <Icon icon={faChevronDown} size="xs" />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {TEXT_TYPES.map((type) => (
          <Dropdown.Item
            key={type.label}
            className={classNames({
              active: EditorUtils.isBlockActive(editor, type.type)
            })}
            onClick={() => EditorUtils.toggleBlock(editor, type.type)}
          >
            {React.createElement(type.element, {}, <>{type.label}</>)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Container>
  );
};

export { MarkdownTextDropdown };
