import { IMSTeamsChannelUpdateSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../../config";
import { request } from "../../../utils/request";
import { patch } from "../../utils/buildRequest";

interface MSTeamsProps {
  tenantId: string;
  filters: object[];
}

async function updateMSTeamsChannels({
  tenantId,
  filters
}: MSTeamsProps): Promise<IMSTeamsChannelUpdateSource> {
  const url = `${getBackendApiUrl()}/integrations/msteams`;
  const options = patch({ tenantId, filters });
  return request(url, options).then((response) => response);
}

export { updateMSTeamsChannels };
