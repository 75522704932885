import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateMSTeamsGroups,
  removeMSTeamsGroup,
  updateMSTeamsFlags
} from "@aptedge/lib-ui/src/redux/reduxSlice/msTeamsSlice";
import {
  IMSTeamsGroup,
  IMSTeamsSource,
  IMSTeamsFlags
} from "@aptedge/lib-ui/src/types/entities";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { updateMSTeamsChannels } from "../../../../clients/SocialSource/MSTeamsSource/updateMSTeamsChannels";

interface MSTeamsListItemConfig {
  isDisabled: boolean;
  updatedGroups: IMSTeamsGroup[];
  isAllSelected: boolean[];
  handleAllSelect: (groupId: string | undefined, index: number) => void;
  handleOnCancel: (index: number) => void;
  handleCheckbox: (channelId: string, groupId: string | undefined) => void;
  handleShowChannels: () => void;
  handleDelete: (groupId: string | undefined) => void;
}

function useMSTeamsList(
  teamSource: IMSTeamsSource | null,
  getDatasource: () => void
): MSTeamsListItemConfig {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isGroupFromSource, setIsGroupFromSource] = useState(false);

  const dispatch = useAppDispatch();
  const {
    msTeamsGroups,
    selectionUpdated,
    checkSelection,
    groupDeleted,
    tenantId
  } = useAppSelector((state) => state.msTeams);

  const [updatedGroups, setUpdatedGroups] = useState<IMSTeamsGroup[]>([]);

  const [isAllSelected, setIsAllSelected] = useState(
    Array(msTeamsGroups.length).fill(false)
  );

  const updateSource = useMutation(updateMSTeamsChannels, {
    onSuccess: () => {
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.RELOAD, value: true }));
      getDatasource();
    }
  });

  const updateSelection = useCallback(
    (selected: boolean | undefined, index: number): void => {
      const newAllSelected = [...isAllSelected];
      newAllSelected[index] = selected;
      setIsAllSelected(newAllSelected);
    },
    [isAllSelected]
  );

  const checkIsDisable = useCallback((): void => {
    if (msTeamsGroups.length === updatedGroups.length) {
      const areListEqual = msTeamsGroups.every((group1, groupIndex) => {
        const group2 = updatedGroups[groupIndex];
        return group1?.channels?.every((channel1, channelIndex) => {
          if (group2?.channels) {
            const channel2 = group2?.channels[channelIndex];
            return channel1.isSelected === channel2.isSelected;
          }
        });
      });
      setIsDisabled(areListEqual);
    }
  }, [updatedGroups, msTeamsGroups]);

  useEffect(() => {
    if (msTeamsGroups && msTeamsGroups.length > 0 && !isGroupFromSource) {
      setUpdatedGroups([...msTeamsGroups]);
      setIsGroupFromSource(true);
    }
  }, [
    setUpdatedGroups,
    setIsGroupFromSource,
    msTeamsGroups,
    isGroupFromSource,
    dispatch
  ]);

  useEffect(() => {
    if (msTeamsGroups && msTeamsGroups.length > 0 && !selectionUpdated) {
      msTeamsGroups.forEach((group, index) => {
        const allSelected = group?.channels?.every(
          (channel) => channel.isSelected
        );
        updateSelection(allSelected, index);
      });
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.SELECT, value: true }));
      setUpdatedGroups([...msTeamsGroups]);
    }
  }, [
    msTeamsGroups,
    selectionUpdated,
    updateSelection,
    dispatch,
    setUpdatedGroups
  ]);

  useEffect(() => {
    if (updatedGroups && !checkSelection) {
      checkIsDisable();
      const newAllSelected = [...isAllSelected];
      updatedGroups.forEach((group, index) => {
        const allSelected = group?.channels?.every(
          (channel) => channel.isSelected
        );
        newAllSelected[index] = allSelected;
      });
      setIsAllSelected(newAllSelected);
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.CHECK, value: true }));
    }
    if (updatedGroups && !groupDeleted) {
      dispatch(removeMSTeamsGroup(updatedGroups));
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.DELETE, value: true }));
    }
  }, [
    updatedGroups,
    checkIsDisable,
    checkSelection,
    dispatch,
    groupDeleted,
    isAllSelected
  ]);

  const handleAllSelect = (
    groupId: string | undefined,
    index: number
  ): void => {
    const newUpdatedGroups = [...updatedGroups];
    const groupToBeUpdated = newUpdatedGroups.find(
      (group) => group.groupId === groupId
    );
    const allSelected = groupToBeUpdated?.channels?.every(
      (channel) => channel.isSelected
    );
    const someSelected = groupToBeUpdated?.channels?.some(
      (channel) => channel.isSelected
    );
    const updatedChannels = groupToBeUpdated?.channels?.map((channel) => ({
      ...channel,
      isSelected:
        !allSelected && someSelected ? true : allSelected ? false : true
    }));
    const updateGroup = {
      ...groupToBeUpdated,
      channels: updatedChannels
    };
    newUpdatedGroups[index] = updateGroup;
    setUpdatedGroups(newUpdatedGroups);
    dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.CHECK, value: false }));
  };

  const handleOnCancel = (index: number): void => {
    const resetGroups = [...updatedGroups];
    resetGroups[index] = {
      ...updatedGroups[index],
      channels: msTeamsGroups[index].channels
    };
    setUpdatedGroups(resetGroups);
    dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.CHECK, value: false }));
  };

  const handleCheckbox = (
    channelId: string,
    groupId: string | undefined
  ): void => {
    const newUpdatedGroups = [...updatedGroups];
    const groupToBeUpdated = newUpdatedGroups.find(
      (group) => group.groupId === groupId
    );
    const groupIndex = newUpdatedGroups.findIndex(
      (group) => group.groupId === groupId
    );
    const updatedChannels = groupToBeUpdated?.channels?.map((channel) => {
      if (channel.channelId === channelId) {
        return {
          ...channel,
          isSelected: !channel.isSelected
        };
      }
      return channel;
    });
    const updateGroup = {
      ...groupToBeUpdated,
      channels: updatedChannels
    };
    newUpdatedGroups[groupIndex] = updateGroup;
    setUpdatedGroups(newUpdatedGroups);
    dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.CHECK, value: false }));
  };

  const handleShowChannels = (): void => {
    const updatePayload = updatedGroups.reduce((groupOp: object[], group) => {
      const channels = group.channels?.reduce(
        (channelOp: string[], channel) => {
          if (channel.isSelected) {
            channelOp.push(channel.channelId);
          }
          return channelOp;
        },
        []
      );
      if (channels && channels.length > 0) {
        const groupObj = { group: group.groupId, channels: [...channels] };
        groupOp.push(groupObj);
      }
      return groupOp;
    }, []);
    if (updatePayload?.length > 0) {
      updateSource.mutate({
        tenantId: tenantId,
        filters: updatePayload
      });
    }
    dispatch(updateMSTeamsGroups(updatedGroups));
    setIsDisabled(true);
  };

  const handleDelete = (groupId: string | undefined): void => {
    const isGroupExist =
      teamSource &&
      teamSource.filters.findIndex((group) => group.groupId === groupId);
    const groups = updatedGroups.filter((group) => group.groupId !== groupId);
    const index = updatedGroups.findIndex((group) => group.groupId === groupId);
    if (index !== -1) {
      const newUpdatedGroup = [...updatedGroups];
      newUpdatedGroup.splice(index, 1);
      setUpdatedGroups(newUpdatedGroup);
      dispatch(
        updateMSTeamsFlags({ mode: IMSTeamsFlags.DELETE, value: false })
      );
    }
    if (isGroupExist !== -1) {
      const channels = groups[0]?.channels?.reduce(
        (channelOp: string[], channel) => {
          if (channel.isSelected) {
            channelOp.push(channel.channelId);
          }
          return channelOp;
        },
        []
      );
      if (channels && channels?.length > 0) {
        updateSource.mutate({
          tenantId: tenantId,
          filters: [{ group: groups[0].groupId, channels: [...channels] }]
        });
      }
      if (channels?.length === 0 || groups.length === 0) {
        updateSource.mutate({
          tenantId: tenantId,
          filters: []
        });
      }
    }
    updateSelection(false, index);
  };

  return {
    isDisabled,
    updatedGroups,
    isAllSelected,
    handleAllSelect,
    handleOnCancel,
    handleCheckbox,
    handleShowChannels,
    handleDelete
  };
}

export { useMSTeamsList };
