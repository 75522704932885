import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  SearchFilterDropdown,
  SearchFilters
} from "@aptedge/lib-ui/src/services/search/searchFilterService";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { ArticleSearchResult } from "../../ArticleSearchResult/ArticleSearchResult";
import { DocumentSearchResult } from "../../DocumentSearchResult/DocumentSearchResult";
import { EdgeSearchResult } from "../../EdgeSearchResult/EdgeSearchResult";
import { FederatedSearchResult } from "../../FederatedSearchResult/FederatedSearchResult";
import { GeneratedKnowledgeSearchResult } from "../../GeneratedKnowledgeSearchResult/GeneratedKnowledgeSearchResult";
import { IssueSearchResult } from "../../IssueSearchResult/IssueSearchResult";
import MsTeamsSearchResult from "../../MsTeamsSearchResult/MsTeamsSearchResult";
import SlackSearchResult from "../../SlackSearchResult/SlackSearchResult";
import { TicketSearchResult } from "../../TicketSearchResult/TicketSearchResult";
import "./GlobalSearchItem.scss";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  className?: string;
  result: ICompositeResult;
  isSelected: boolean;
}

const GlobalSearchItem: React.FC<Props> = ({
  result,
  isSelected,
  className,
  ...htmlProps
}) => {
  const { flags } = useFlags();
  const { filters: searchFilters, selectedSearchResult } = useAppSelector(
    (state) => state.search
  );

  const getFilterDetails = (filter: string): SearchFilterDropdown[] => {
    const [searchFilter] = searchFilters?.filter(
      (filterItem: SearchFilters) => filterItem.key === filter
    );

    return searchFilter?.filters || [];
  };

  const showActionIcons =
    selectedSearchResult && selectedSearchResult.id === result.id;

  if (result.type === "edge") {
    return (
      <EdgeSearchResult
        className={classNames("global-result", className, {
          "global-result-v1": !flags.answerGptUiV2
        })}
        edge={result}
        isSelected={isSelected}
        showIcons={false}
        showHighlight={true}
        showActionIcons={showActionIcons}
        {...htmlProps}
      />
    );
  } else if (result.type === "article") {
    return (
      <ArticleSearchResult
        className={classNames("global-result", className, {
          "global-result-v1": !flags.answerGptUiV2
        })}
        isSelected={isSelected}
        article={result}
        filterDetails={getFilterDetails("articles")}
        showActionIcons={showActionIcons}
        {...htmlProps}
      />
    );
  } else if (result.type === "generated_knowledge") {
    return (
      <GeneratedKnowledgeSearchResult
        className={classNames("global-result", className, {
          "global-result-v1": !flags.answerGptUiV2
        })}
        isSelected={isSelected}
        article={result}
        filterDetails={getFilterDetails("articles")}
        showActionIcons={showActionIcons}
        {...htmlProps}
      />
    );
  } else if (result.type === "document") {
    return (
      <DocumentSearchResult
        className={classNames("global-result", className, {
          "global-result-v1": !flags.answerGptUiV2
        })}
        isSelected={isSelected}
        document={result}
        showActionIcons={showActionIcons}
        {...htmlProps}
      />
    );
  } else if (result.type === "ticket") {
    return (
      <TicketSearchResult
        className={classNames("global-result", className, {
          "global-result-v1": !flags.answerGptUiV2
        })}
        isSelected={isSelected}
        ticket={result}
        showActionIcons={showActionIcons}
        {...htmlProps}
      />
    );
  } else if (result.type === "issue") {
    return (
      <IssueSearchResult
        className={classNames("global-result", className, {
          "global-result-v1": !flags.answerGptUiV2
        })}
        isSelected={isSelected}
        issue={result}
        showActionIcons={showActionIcons}
        {...htmlProps}
      />
    );
  } else if (result.type === "social") {
    if (result.sourceType === "slack") {
      return (
        <SlackSearchResult
          isSelected={isSelected}
          slackDetails={result}
          showActionIcons={showActionIcons}
          {...htmlProps}
        />
      );
    } else if (result.sourceType === "microsoft-teams") {
      return (
        <MsTeamsSearchResult
          isSelected={isSelected}
          msTeamsDetails={result}
          showActionIcons={showActionIcons}
          {...htmlProps}
        />
      );
    }
  } else if (result.type === "federated") {
    return (
      <FederatedSearchResult
        className={classNames("global-result", className, {
          "global-result-v1": !flags.answerGptUiV2
        })}
        isSelected={isSelected}
        federated={result}
        showActionIcons={showActionIcons}
        {...htmlProps}
      />
    );
  }
  return null;
};

export { GlobalSearchItem };
