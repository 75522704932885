import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useSearchPreview } from "@aptedge/lib-ui/src/hooks/useSearchPreview";
import {
  ICompositeResult,
  IIssueListing,
  ISSUES_SOURCE_TYPE
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { ReactNode } from "react";
import { displayAge } from "../../utils/time";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";
import WithLoading from "../WithLoading/WithLoading";
import "./IssueItem.scss";

interface Props {
  className?: string;
  issue: IIssueListing;
  showActionIcons?: boolean;
  actions?: React.ReactElement;
  disabled?: boolean;
  isLoading?: boolean;
  onSelect?: (issue: IIssueListing) => void;
}

const IssueItem: React.FC<Props> = ({
  className,
  issue,
  showActionIcons,
  actions,
  disabled,
  onSelect,
  isLoading = false
}) => {
  const { flags } = useFlags();
  const { hasPreview, previewSnippet, previewType } = useSearchPreview({
    ...issue,
    type: "issue"
  });

  const getIssuesIcon = (): ReactNode => {
    const sourceType = issue?.sourceType;
    switch (sourceType) {
      case ISSUES_SOURCE_TYPE.JIRA:
        return <AeIcons.Issues className="mr-3 p-0" />;
      case ISSUES_SOURCE_TYPE.AZURE_DEVOPS:
        return <AeIcons.AzureDevops className="mr-3 p-0" />;
      default:
        return <AeIcons.IssuesIcon className="mr-3 p-0" />;
    }
  };

  return (
    <div
      className={classNames("issue-item", className, {
        disabled,
        clickable: !!onSelect,
        "issue-item-v1": !flags.answerGptUiV2
      })}
    >
      <div className="heading">
        <h4 onClick={!!onSelect ? () => onSelect(issue) : undefined}>
          {getIssuesIcon()}
          <span className="subject">
            <SearchResultPreview
              previewSnippet={
                hasPreview && previewType === "title"
                  ? previewSnippet
                  : issue.title
              }
            />
          </span>
        </h4>
        {showActionIcons && (
          <div className="issues-header-action-icons">
            <SearchResultCardActionIcons
              searchResult={issue as ICompositeResult}
            />
          </div>
        )}
        <div className="right">
          <WithLoading isLoading={isLoading}>
            {!disabled && !!actions && <div>{actions}</div>}
          </WithLoading>
        </div>
      </div>
      {hasPreview && previewType === "description" && (
        <div className="issue-description">
          <SearchResultPreview previewSnippet={previewSnippet} />
        </div>
      )}
      <div className="issue-summary">
        <div className="text-muted">
          <p className="placeholder text-gray-400">{issue.url}</p>
        </div>
      </div>
      {issue?.createdOn && (
        <div className="issue_card_time">
          <div>{`Created ${displayAge(issue?.createdOn)}`}</div>
        </div>
      )}
    </div>
  );
};

export { IssueItem };
