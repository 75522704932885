import SearchFeedback, {
  FeedbackSource
} from "@aptedge/lib-ui/src/components/SearchFeedback/SearchFeedback";
import useAiAnswers from "@aptedge/lib-ui/src/features/AiAnswer/useAiAnswers";
import classNames from "classnames";
import { FC } from "react";
import AiAnswerPreview from "./AiAnswerPreview";
import "./AnswerPreview.scss";

interface IAnswers {
  showFeedback: boolean;
}

const AnswerPreview: FC<IAnswers> = ({ showFeedback }) => {
  const { isAiSearchQuery, flags, answerRef, answerId } = useAiAnswers();
  return (
    <>
      {isAiSearchQuery && (
        <div
          className={classNames("answers-preview-container", {
            "answers-preview-container-v1": !flags.answerGptUiV2
          })}
          ref={answerRef}
        >
          <AiAnswerPreview />
          {showFeedback && (
            <SearchFeedback
              showFeedback={showFeedback}
              feedbackOptions={flags.feedbackOptions.answerFeedbackLabels}
              eventSource={FeedbackSource.ANSWERGPT}
              key={answerId}
              feedbackId={answerId}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AnswerPreview;
