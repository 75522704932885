import SearchFeedback, {
  FeedbackSource
} from "@aptedge/lib-ui/src/components/SearchFeedback/SearchFeedback";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { GPTContext } from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/AiAnswer";
import { WelcomePage } from "@aptedge/lib-ui/src/features/WelcomePage/WelcomePage";
import classNames from "classnames";
import Helmet from "react-helmet";
import { AutoComplete } from "../../components/AutoComplete/AutoComplete";
import { GlobalSearchList } from "../../components/GlobalSearch/GlobalSearchList/GlobalSearchList";
import { PaginationBar } from "../../components/PaginationBar/PaginationBar";
import AnswerResult from "./AnswerResult";
import GlobalSearchFilter from "./GlobalSearchFilter";
import "./GlobalSearchPage.scss";
import useGlobalSearch from "./hooks/useGlobalSearch";
import SearchResultPreviewCard from "./SearchResultPreview/SearchResultPreviewCard";

const GlobalSearchPage: React.FC = () => {
  const {
    isSearchListRefActive,
    searchContainerRef,
    handleActiveRefForSearchList,
    searchRef,
    flags,
    gptAnswer,
    refetchAnswer,
    searchResultsArrowNavigation,
    userData,
    isLoading,
    showFeedback,
    page,
    totalPages,
    searchQuery,
    answerId,
    handlePaginationChange,
    searchResults
  } = useGlobalSearch();

  if (isLoading) return <Spinner />;

  if (!userData?.onboardingComplete) return <WelcomePage />;

  return (
    <>
      <Helmet>
        <title>Search</title>
      </Helmet>
      <GPTContext.Provider value={{ gptAnswer, refetchAnswer }}>
        <div
          className={classNames("global-search-page-container", {
            "global-search-page-container-v1": !flags.answerGptUiV2
          })}
        >
          <div
            className="global-search-page-main"
            ref={isSearchListRefActive ? searchContainerRef : null}
          >
            <div>
              <AutoComplete
                handleActiveRefForSearchList={handleActiveRefForSearchList}
                searchRef={searchRef}
              />
              <GlobalSearchFilter />
              {flags.answerGptUiV2 ? (
                <hr />
              ) : (
                <>
                  {showFeedback && (
                    <SearchFeedback
                      showFeedback={showFeedback}
                      feedbackOptions={
                        flags.feedbackOptions.searchFeedbackLabels
                      }
                      eventSource={FeedbackSource.SEARCH}
                      feedbackId={answerId || String(searchResults[0]?.id)}
                    />
                  )}
                </>
              )}
            </div>
            <div className="global-search-page-results">
              <div
                className={classNames("global-search-results-container", {
                  "global-search-results-container-v1": !flags.answerGptUiV2
                })}
              >
                <AnswerResult answerId={answerId} flags={flags} />
                <GlobalSearchList
                  arrowNavigation={searchResultsArrowNavigation}
                />
              </div>
              {flags.answerGptUiV2 && (
                <SearchResultPreviewCard showFeedback={showFeedback} />
              )}
            </div>
            {(searchQuery || Boolean(searchResults.length)) &&
              !flags.answerGptUiV2 && (
                <div className="search-pagination-bar-container">
                  <PaginationBar
                    page={page}
                    totalPages={totalPages}
                    onChange={handlePaginationChange}
                  />
                </div>
              )}
          </div>
          {!flags.answerGptUiV2 && (
            <SearchResultPreviewCard showFeedback={showFeedback} />
          )}
        </div>
      </GPTContext.Provider>
    </>
  );
};

export default GlobalSearchPage;
