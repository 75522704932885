import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faJira } from "@fortawesome/free-brands-svg-icons";
import {
  faAngry,
  faCalendarAlt,
  faCheckSquare,
  faClock,
  faCopy,
  faObjectGroup,
  faSave,
  faSquare,
  faStar,
  faTrashAlt
} from "@fortawesome/free-regular-svg-icons";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faArchive,
  faArrowCircleLeft,
  faArrowCircleRight,
  faArrowLeft,
  faArrowRight,
  faBolt,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
  faCompress,
  faDownload,
  faEdit,
  faEllipsisH,
  faExclamation,
  faExclamationCircle,
  faExpand,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileImport,
  faHome,
  faInfo,
  faLightbulb,
  faLink,
  faMoneyBill,
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faRedo,
  faSearch,
  faShareAlt,
  faShareSquare,
  faStar as faStarFilled,
  faStream,
  faTimes,
  faTimesCircle,
  faTools,
  faUser,
  faUserPlus,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import { Icon, IconProps } from "./Icon";
import "./Icon.scss";

type WithIconComponent = React.FC<Props>;

const withIcon = (
  component: React.FC<IconProps>,
  icon: IconDefinition,
  className: string
): WithIconComponent => {
  const WrappedComponent = component;

  const Icon: WithIconComponent = ({
    onClick,
    size,
    className: classNameProp,
    ...rest
  }) => (
    <span
      onClick={onClick}
      className={classNames("icon", className, classNameProp, {
        link: !!onClick
      })}
      data-testid={className}
      {...rest}
    >
      <WrappedComponent icon={icon} size={size} />
    </span>
  );

  return Icon;
};

interface Props extends HTMLAttributes<HTMLSpanElement> {
  size?: SizeProp;
}

const Icons = {
  AlertCircle: withIcon(Icon, faExclamationCircle, "alert-icon"),
  AngleDoubleLeft: withIcon(Icon, faAngleDoubleLeft, "angle-double-left-icon"),
  AngleDoubleRight: withIcon(
    Icon,
    faAngleDoubleRight,
    "angle-double-right-icon"
  ),
  Angry: withIcon(Icon, faAngry, "angry-icon"),
  AngleLeft: withIcon(Icon, faAngleLeft, "angle-left-icon"),
  AngleRight: withIcon(Icon, faAngleRight, "angle-right-icon"),
  AngleUp: withIcon(Icon, faAngleUp, "angle-up-icon"),
  Archive: withIcon(Icon, faArchive, "archive-icon"),
  ArrowCircleLeft: withIcon(Icon, faArrowCircleLeft, "arrow-circle-left"),
  ArrowCircleRight: withIcon(Icon, faArrowCircleRight, "arrow-circle-left"),
  ArrowLeft: withIcon(Icon, faArrowLeft, "arrow-left"),
  ArrowRight: withIcon(Icon, faArrowRight, "arrow-right"),
  Bolt: withIcon(Icon, faBolt, "bolt-icon"),
  CalendarAlt: withIcon(Icon, faCalendarAlt, "calendar-alt-icon"),
  CaretUp: withIcon(Icon, faCaretUp, "caret-up-icon"),
  CaretDown: withIcon(Icon, faCaretDown, "caret-down-icon"),
  Clock: withIcon(Icon, faClock, "clock-icon"),
  Check: withIcon(Icon, faCheck, "check-icon"),
  CheckSquare: withIcon(Icon, faCheckSquare, "check-square-icon"),
  ChevronUp: withIcon(Icon, faChevronUp, "chevron-up-icon"),
  ChevronDown: withIcon(Icon, faChevronDown, "chevron-down-icon"),
  ChevronLeft: withIcon(Icon, faChevronLeft, "chevron-left-icon"),
  ChevronRight: withIcon(Icon, faChevronRight, "chevron-right-icon"),
  Compress: withIcon(Icon, faCompress, "compress-icon"),
  Copy: withIcon(Icon, faCopy, "copy-icon"),
  Download: withIcon(Icon, faDownload, "download-icon"),
  Edit: withIcon(Icon, faEdit, "edit-icon"),
  Elipsis: withIcon(Icon, faEllipsisH, "elipsis-icon"),
  Expand: withIcon(Icon, faFileImport, "expand-icon"),
  ExternalLink: withIcon(Icon, faExternalLinkAlt, "external-link-icon"),
  Eye: withIcon(Icon, faEye, "eye-icon"),
  Fullscreen: withIcon(Icon, faExpand, "fullscreen-icon"),
  EyeSlash: withIcon(Icon, faEyeSlash, "eye-slash-icon"),
  Exclamation: withIcon(Icon, faExclamation, "exclamation-icon"),
  File: withIcon(Icon, faFile, "file-icon"),
  FileAlt: withIcon(Icon, faFileAlt, "file-alt-icon"),
  Home: withIcon(Icon, faHome, "home-icon"),
  Info: withIcon(Icon, faInfo, "info-icon"),
  Jira: withIcon(Icon, faJira, "jira-icon"),
  Lightbulb: withIcon(Icon, faLightbulb, "lightbulb-icon"),
  Link: withIcon(Icon, faLink, "link-icon"),
  MoneyBill: withIcon(Icon, faMoneyBill, "money-bill-icon"),
  ObjectGroup: withIcon(Icon, faObjectGroup, "object-group-icon"),
  PaperPlane: withIcon(Icon, faPaperPlane, "plane-icon"),
  Plus: withIcon(Icon, faPlus, "add-icon"),
  PlusCircle: withIcon(Icon, faPlusCircle, "add-icon"),
  Redo: withIcon(Icon, faRedo, "redo-icon"),
  Save: withIcon(Icon, faSave, "save-icon"),
  Search: withIcon(Icon, faSearch, "search-icon"),
  Settings: withIcon(Icon, faCog, "settings-icon"),
  Share: withIcon(Icon, faShareAlt, "share-icon"),
  ShareSquare: withIcon(Icon, faShareSquare, "share-square-icon"),
  Square: withIcon(Icon, faSquare, "square-icon"),
  Star: withIcon(Icon, faStar, "star-icon"),
  StarFilled: withIcon(Icon, faStarFilled, "star-filled-icon"),
  Stream: withIcon(Icon, faStream, "stream-icon"),
  Times: withIcon(Icon, faTimes, "close-icon"),
  TimesCircle: withIcon(Icon, faTimesCircle, "times-circle-icon"),
  Tools: withIcon(Icon, faTools, "tools-icon"),
  Trash: withIcon(Icon, faTrashAlt, "trash-icon"),
  User: withIcon(Icon, faUser, "user-icon"),
  UserPlus: withIcon(Icon, faUserPlus, "user-plus-icon")
};

export { Icons };
