import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { useClipboard } from "@aptedge/lib-ui/src/hooks/useClipboard";
import { faClipboard, faPlus, faSync } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import { ClientStatus } from "../../../types/clients";

type Response = { token: string };

interface IProps {
  value?: string;
  createToken: () => Promise<Response>;
  onTokenCreated: () => void;
}

const Token: React.FC<IProps> = ({ value, createToken, onTokenCreated }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [status, setStatus] = React.useState<ClientStatus>(ClientStatus.PROMPT);

  const createTokenMutation = useMutation(createToken, {
    onSuccess: (res) => {
      setModalOpen(false);
      onTokenCreated();
      setStatus(ClientStatus.PROMPT);
    }
  });

  const handleConfirmGenerate = (): void => {
    setStatus(ClientStatus.LOADING);
    createTokenMutation.mutate();
  };

  const { handleCopy, copied, error } = useClipboard();
  return (
    <>
      <div className="input-group">
        <input
          id="token"
          className="form-control"
          type="text"
          value={value ?? ""}
          readOnly
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn generate"
            data-testid="token-generate-button"
            onClick={() => setModalOpen(true)}
          >
            <span className="p-1 align-middle">
              <Icon icon={value ? faSync : faPlus} />
            </span>
            <span className="p-1 align-middle">Token</span>
          </button>
        </div>
        <div className="input-group-append" hidden={value === undefined}>
          <button
            className="btn clippy"
            data-testid="clippy-token"
            type="button"
            onClick={() => handleCopy(value ?? "")}
          >
            <span className="p-2">
              <Icon icon={faClipboard} />
            </span>
          </button>
        </div>
      </div>

      <ConfirmationModal
        isOpen={modalOpen}
        status={status}
        title="Generate new token?"
        loadingTitle="Generating..."
        onClose={() => setModalOpen(false)}
      >
        <p>
          Generating a new token will cause any existing token to be invalid.
        </p>
        <p>
          You must add this new token to your Zendesk app settings, otherwise
          the app will no longer work.
        </p>
        <div className="mt-4 d-flex justify-content-end">
          <Button color="danger" onClick={() => handleConfirmGenerate()}>
            Generate
          </Button>
        </div>
      </ConfirmationModal>
      {copied && (
        <Toast type={ToastType.INFO} autoClose={2000}>
          Copied to clipboard!
        </Toast>
      )}
      {error && (
        <Toast type={ToastType.ERROR} autoClose={2000}>
          Unable to copy to clipboard.
        </Toast>
      )}
    </>
  );
};

export { Token };
