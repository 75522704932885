import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IntegrationStatus } from "@aptedge/lib-ui/src/types/entities";
import { faEdit, IconDefinition } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faExclamationCircle,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import "./StatusButton.scss";

interface Props {
  status: IntegrationStatus;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  onRetry?: () => void;
  handleReAuth?: () => void;
  isReAuthVisible?: boolean;
}

interface IIconDefinition {
  icon: IconDefinition;
  color: string;
  content: string;
}

const StatusButton: React.FC<Props> = (props) => {
  const {
    status,
    children,
    onClickEdit,
    onClickDelete,
    onRetry,
    isReAuthVisible,
    handleReAuth
  } = props;

  const { content: tooltipContent, ...iconProps } = getIconDefinition(status);

  return (
    <div className="status-button d-flex rounded justify-content-between">
      <div className="d-flex align-items-center">
        <div>
          <Tooltip content={tooltipContent}>
            <Icon size="lg" {...iconProps} />
          </Tooltip>
        </div>
        <div className="ml-3">{children}</div>
      </div>
      <div className="icons">
        {status === "timeOut" && (
          <Tooltip content="We are trying to reconnect to the server! Please wait for a moment.">
            <button className="retry" onClick={onRetry}>
              Reconnecting...
            </button>
          </Tooltip>
        )}
        {onClickEdit && status !== "timeOut" && (
          <button className="btn" onClick={onClickEdit}>
            <Icon icon={faEdit} data-testid="status-button-edit" />
          </button>
        )}
        {onClickDelete && (
          <button className="btn delete" onClick={onClickDelete}>
            <Icon icon={faEdit} data-testid="status-button-delete" />
          </button>
        )}
        {isReAuthVisible && (
          <Button className="btn ae-button" onClick={handleReAuth}>
            <span>Re-Authorize</span>
          </Button>
        )}
      </div>
    </div>
  );
};

const getIconDefinition = (status: IntegrationStatus): IIconDefinition => {
  switch (status) {
    case "ok":
      return {
        icon: faCheckCircle,
        color: "var(--green)",
        content: "Connected"
      };
    case "timeOut":
      return {
        icon: faClock,
        color: "var(--light-orange)",
        content:
          "Connection timeout: Please wait for a moment or re-enter your credentials and try again."
      };
    case "error":
      return {
        icon: faExclamationCircle,
        color: "var(--danger)",
        content:
          "Connection failed: Incorrect credentials, please verify your details and try again."
      };
  }
};

export { StatusButton };
