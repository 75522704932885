import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { useClipboard } from "@aptedge/lib-ui/src/hooks/useClipboard";
import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { createNewZendeskAppToken } from "../../../clients/TicketSources/createNewZendeskAppToken";
import { useTicketDataSource } from "../../../hooks/clients/useDataSource";
import { Token } from "./Token";

function SupportAppSetup(): React.ReactElement {
  const ticketResource = useTicketDataSource();
  const appToken = ticketResource?.dataSource?.appToken;

  const { handleCopy } = useClipboard();
  return (
    <>
      <div>
        <h5>Setup</h5>
        <p>
          Be sure to use the Base URL and Token below when setting up our app.
        </p>
        <div className="settings">
          <label htmlFor="baseUrl">Base URL</label>
          <div className="input-group">
            <input
              id="baseUrl"
              type="text"
              className="form-control"
              defaultValue={window.location.hostname}
              readOnly
            />
            <div className="input-group-append">
              <button
                className="btn clippy"
                type="button"
                data-testid="clippy-domain"
                data-clipboard-target="#baseUrl"
                onClick={() => handleCopy(window.location.hostname)}
              >
                <span className="p-2">
                  <Icon icon={faClipboard} />
                </span>
              </button>
            </div>
          </div>
          <div className="mt-3">
            <label htmlFor="token">Token</label>
            <Token
              value={appToken}
              createToken={createNewZendeskAppToken}
              onTokenCreated={ticketResource.reload}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export { SupportAppSetup };
