import { IFlagSet } from "@aptedge/lib-ui/src/context/FlagsContext/types";
import AiAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/AiAnswer";
import useAiAnswers from "@aptedge/lib-ui/src/features/AiAnswer/useAiAnswers";
import { FC } from "react";
import { AnswerSearchResult } from "../../components/AnswerSearchResult/AnswerSearchResult";

type AnswerResultType = {
  answerId: string;
  flags: IFlagSet;
};

const AnswerResult: FC<AnswerResultType> = ({ answerId, flags }) => {
  const { isAiSearchQuery } = useAiAnswers();
  return (
    <>
      {Boolean(answerId && isAiSearchQuery) && (
        <>{flags.answerGptUiV2 ? <AnswerSearchResult /> : <AiAnswer />}</>
      )}
    </>
  );
};

export default AnswerResult;
