import {
  AptAnswersFilterRule,
  AptAnswersFilterType
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

interface UpdateFilterArgs {
  filterId: string;
  filterName: string;
  filterType: AptAnswersFilterType;
  filterRules: AptAnswersFilterRule[];
}

interface UpdateFilterResponse {
  filterId: string;
}

async function updateFilter({
  filterId,
  filterName,
  filterType,
  filterRules
}: UpdateFilterArgs): Promise<UpdateFilterResponse> {
  const url = `${getBackendApiUrl()}/apt-answer-filter/${filterId}`;
  const options = patch({ name: filterName, filterType, filterRules });
  return request(url, options);
}

export { updateFilter };
