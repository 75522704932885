/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLatest } from "@aptedge/lib-ui/src/hooks/useLatest";
import {
  fromLZCompressedString,
  toLZCompressedString
} from "@aptedge/lib-ui/src/utils/query";
import { Search } from "history";
import type { Dictionary } from "lodash";
import pick from "lodash/pick";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

function getLZQuery<T>(search: Search): Partial<T> {
  const searchParams = new URLSearchParams(search);
  return fromLZCompressedString(searchParams.get("q") ?? "");
}

/**
 * A hook that gets the current LZ query string from the URL and parses it into an object,
 * and overlays the given defaults to replace any undefined values (not in the URL).
 *
 * @param defaultValues the defaults that should be used if those values are not in the URL.
 */
function useLZQuery<T extends object>(
  defaultValues: T
): [T, (updates: Partial<T>) => void] {
  const history = useHistory();
  const { search } = useLocation();

  const defaults = useLatest(defaultValues);

  const queryParams = useMemo(() => {
    const allParams = getLZQuery(search);

    // If there were defaults, only keep matching keys.
    const whitelistedKeys = Object.keys(defaults);
    const values: Dictionary<any> = !!whitelistedKeys.length
      ? pick(allParams, whitelistedKeys)
      : allParams;

    // Override the defaults with boxed values.
    return {
      ...defaults,
      ...values
    };
  }, [defaults, search]);

  const setQueryParams = useCallback(
    (changes: Partial<T>): void => {
      const allParams = getLZQuery(search);
      const whitelistedKeys = Object.keys(defaults);

      // Only allow this consumer to change keys based on defaultValues.
      const allowedChanges = pick(changes, whitelistedKeys);
      const updated = {
        ...allParams,
        ...allowedChanges
      };

      const newSearchParams = new URLSearchParams();
      newSearchParams.set("q", toLZCompressedString(updated));
      history.replace({ search: newSearchParams.toString() });
    },
    [defaults, search, history]
  );

  return [queryParams, setQueryParams];
}

export { useLZQuery, getLZQuery };
