import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateSortMode } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  ISearchResultSortingModes,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { ChangeEvent, FC } from "react";

interface Props {
  className?: string;
}
const GlobalSearchSort: FC<Props> = ({ className }) => {
  const { sortMode } = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();
  const { updateParams } = useQueryParams();

  const handleSort = (e: ChangeEvent<HTMLSelectElement>): void => {
    dispatch(updateSortMode(e.target.value as ISearchResultSortingModes));
    updateParams(QUERY_PARAMS.SORT, e.target.value);
  };

  return (
    <select className={className} onChange={handleSort} value={sortMode}>
      <option value={ISearchResultSortingModes.RELEVANCE}>Relevance</option>
      <option value={ISearchResultSortingModes.CREATED_DATE}>
        Created Date
      </option>
      <option value={ISearchResultSortingModes.UPDATED_DATE}>
        Updated Date
      </option>
    </select>
  );
};

export default GlobalSearchSort;
