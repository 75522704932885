import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { Delay } from "../../styles/animations";
import { Button } from "../Button/Button";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";

interface Props {
  active: boolean;
  icon: IconDefinition;
  disabled?: boolean;
  onMouseDown: () => void;
  tooltip?: string;
}

const ToolbarButton: React.FC<Props> = ({
  active,
  disabled,
  icon,
  onMouseDown,
  tooltip
}) => {
  return (
    <Tooltip disabled={!tooltip} delay={Delay.LONG} content={tooltip || null}>
      <Button
        active={active}
        disabled={disabled}
        color="secondary"
        className="pr-2 pl-2"
        onMouseDown={(event) => {
          event.preventDefault();
          onMouseDown();
        }}
      >
        <Icon icon={icon} />
      </Button>
    </Tooltip>
  );
};

export { ToolbarButton };
