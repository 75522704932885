import React, { ComponentType, ReactElement, ReactNode } from "react";

/*
 * Find and return all matching children by type.
 * Based on: https://github.com/recharts/recharts/blob/master/src/util/ReactUtils.js#L222
 */
function findChildrenByType<T>(
  children: ReactNode | Array<ReactNode>,
  component: ComponentType<T>
): Array<ReactElement<T>> {
  const result: Array<ReactElement<T>> = [];

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child) && !!child.type) {
      const type = child.type as React.ComponentType;
      const childType: string = type.displayName || type.name;

      if (childType === getDisplayName(component)) {
        result.push(child);
      }
    }
  });

  return result;
}

/**
 * Finds a specific type of element from children, removes it from children, and
 * returns it and the remaining children separately in a tuple: [child, remainingChilren]
 *
 * @param children The original children.
 * @param type The type of react component to extract from children.
 */
function extractChildByType<T>(
  children: ReactNode | Array<ReactNode>,
  component: ComponentType<T>
): [ReactElement<T> | null, Array<ReactNode>] {
  let matchingElement: ReactElement<T> | null = null;
  const remainingChildren: Array<ReactNode> = [];

  React.Children.forEach(children, (child) => {
    let shouldIncludInRemainingChildren = true;
    if (React.isValidElement(child) && !!child.type) {
      const type = child.type as React.ComponentType;
      const childType: string = type.displayName || type.name;

      if (childType === getDisplayName(component)) {
        matchingElement = matchingElement ? matchingElement : child;
        shouldIncludInRemainingChildren = false;
      }
    }

    if (shouldIncludInRemainingChildren) {
      remainingChildren.push(child);
    }
  });

  return [matchingElement, remainingChildren];
}

/*
 * Return the first matched child by type, return null otherwise.
 */
function findChildByType<T>(
  children: ReactNode | Array<ReactNode>,
  type: ComponentType<T>
): ReactElement<T> | null {
  const result = findChildrenByType(children, type);

  return result.length ? result[0] : null;
}

/**
 * Get the display name of a component
 * @param  {Object} Comp Specified Component
 * @return {String}      Display name of Component
 */
function getDisplayName<T>(Comp: React.ComponentType<T>): string {
  return Comp.displayName || Comp.name || "Component";
}

export { extractChildByType, findChildrenByType, findChildByType };
