import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IRichTextContent,
  TextEditor
} from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import {
  ITemplate,
  TemplateLocation
} from "@aptedge/lib-ui/src/types/entities";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { createTemplate } from "../../../clients/Templates/createTemplate";
import { deleteTemplate } from "../../../clients/Templates/deleteTemplate";
import { updateTemplate } from "../../../clients/Templates/updateTemplate";
import WithLoading from "../../../components/WithLoading/WithLoading";
import "./TemplateItem.scss";

interface Props {
  location: TemplateLocation;
  template: ITemplate | null;
  onChange: () => void;
}

const classes = {
  statusBar: "template-status-bar",
  addBar: "add-template-bar"
} as const;

const TemplateItem: React.FC<Props> = (props) => {
  const { location, template, onChange } = props;

  const content = template
    ? { content: template.content, version: template.version }
    : null;

  const [showModal, setShowModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const addTemplate = useMutation(createTemplate, { onSuccess: onChange });
  const editTemplate = useMutation(updateTemplate, { onSuccess: onChange });
  const removeTemplate = useMutation(deleteTemplate, { onSuccess: onChange });

  const isLoading = [addTemplate, editTemplate, removeTemplate].some(
    (s) => s.isLoading
  );

  const label = getLabelForLocation(location);

  const handleDelete = (): void => {
    if (template) {
      removeTemplate.mutate({ templateId: template.id });
    }
    setShowDeleteConfirm(false);
  };

  const handleSave = (newContent: IRichTextContent): void => {
    if (template) {
      editTemplate.mutate({
        templateId: template.id,
        content: newContent.content
      });
    } else {
      addTemplate.mutate({ location, ...newContent });
    }
    setShowModal(false);
  };

  return (
    <div className="template-item">
      <WithLoading isLoading={isLoading} fallback={<Spinner />} type="overlay">
        {template ? (
          <div className={classes.statusBar}>
            {!showDeleteConfirm ? (
              <>
                <div className="d-flex align-items-center">
                  <div>
                    <Icon size="lg" icon={faCheckCircle} color="var(--green)" />
                  </div>
                  <div className="ml-3">
                    <div>{label}</div>
                    <div>
                      <small className="text-muted">Markdown template</small>
                    </div>
                  </div>
                </div>
                <div className="icons">
                  <button className="btn" onClick={() => setShowModal(true)}>
                    <Icon icon={faEdit} data-testid="template-item-edit" />
                  </button>
                  <button
                    className="btn delete"
                    onClick={() => setShowDeleteConfirm(true)}
                  >
                    <Icon
                      icon={faTrashAlt}
                      data-testid="template-item-delete"
                    />
                  </button>
                </div>
              </>
            ) : (
              <>
                <span className="text-muted pr-2">
                  Are you sure you want to delete the <strong>{label}</strong>?
                </span>
                <div className="d-flex">
                  <Button
                    color="secondary"
                    className="mr-2"
                    onClick={() => setShowDeleteConfirm(false)}
                  >
                    Cancel
                  </Button>
                  <Button color="danger" onClick={handleDelete}>
                    Delete
                  </Button>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className={classes.addBar} onClick={() => setShowModal(true)}>
            <div>
              <Icon size="lg" icon={faPlusCircle} />
              <span className="ml-3">{label}</span>
            </div>
          </div>
        )}
      </WithLoading>
      <Modal
        title={label}
        width={ModalWidth.LARGE}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p>
          Customize your template in the editor below. This will act as a guide
          for your team.
        </p>
        {showModal && (
          <TextEditor
            defaultOpen
            content={content}
            onCancel={() => setShowModal(false)}
            onSave={handleSave}
            isImageDropAllowed={false}
          />
        )}
      </Modal>
    </div>
  );
};

function getLabelForLocation(loc: TemplateLocation): string {
  switch (loc) {
    case TemplateLocation.EDGE_DESCRIPTION:
      return "Edge Description Template";
    case TemplateLocation.TICKET_DESCRIPTION:
      return "Agent Description Template";
  }
}

export { TemplateItem };
