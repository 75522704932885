import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  ICompositeResult,
  ISocialResult
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { FC } from "react";
import SearchCardTimings from "../SearchCardTimings/SearchCardTimings";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";
import "./SlackSearchResult.scss";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  className?: string;
  slackDetails: ISocialResult;
  isSelected?: boolean;
  showActionIcons?: boolean;
}
const SlackSearchResult: FC<Props> = ({
  slackDetails,
  isSelected,
  showActionIcons,
  ...htmlProps
}) => {
  const { flags } = useFlags();
  const { preview, channel, createdOn, updatedOn } = slackDetails;
  return (
    <div {...htmlProps}>
      <div
        className={classNames("slack_card_container global-result", {
          active: isSelected,
          "slack-card-container-v1 global-result-v1": !flags.answerGptUiV2
        })}
      >
        <div className="slack_card_header">
          <div className="slack_card_header_icon">
            <AeIcons.Slack />
          </div>

          {channel && (
            <div className="slack_card_header_channel">#{channel}</div>
          )}
          {showActionIcons && (
            <div className="social-header-action-icons">
              <SearchResultCardActionIcons
                searchResult={slackDetails as ICompositeResult}
              />
            </div>
          )}
        </div>
        <SearchResultPreview previewSnippet={preview} />
        <SearchCardTimings
          createdOn={createdOn}
          updatedOn={updatedOn}
          createdVerb="Sent"
        />
      </div>
    </div>
  );
};

export default SlackSearchResult;
