import { AptAnswersFilterPurpose } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterActions.module.scss";
import { FilterAddRuleButton } from "./FilterAddRuleButton";
import { FilterManageAllOption } from "./FilterManageAllOption";
import { FilterSaveButton } from "./FilterSaveButton";

interface FilterActionsProps {
  handleSave: () => void;
  handleAddRule: () => void;
  handleManageAllClick: () => void;
  manageAllSelected: boolean;
  addRuleIsDisabled: boolean;
  integrationName: string;
  filterPurpose: AptAnswersFilterPurpose;
}

const FilterActions: React.FC<FilterActionsProps> = ({
  handleSave,
  handleAddRule,
  handleManageAllClick,
  manageAllSelected,
  addRuleIsDisabled,
  integrationName,
  filterPurpose
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        <FilterSaveButton handleSave={handleSave} />
        <FilterAddRuleButton
          handleAddRule={handleAddRule}
          isDisabled={addRuleIsDisabled}
        />
      </div>
      <div className={styles.manageAllContainer}>
        <FilterManageAllOption
          integrationName={integrationName}
          manageAllSelected={manageAllSelected}
          handleManageAllClick={handleManageAllClick}
          filterPurpose={filterPurpose}
        />
      </div>
    </div>
  );
};

export { FilterActions };
