import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import { IEdgeInfo, ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import pluralize from "pluralize";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchTicketsForEdge } from "../../../clients/Edges/fetchTicketsForEdge";
import { toSortString } from "../../../clients/utils/sort";
import { PaginationAnalytics } from "../../../components/PaginationAnalytics/PaginationAnalytics";
import { PaginationBar } from "../../../components/PaginationBar/PaginationBar";
import { TicketItem } from "../../../components/TicketItem/TicketItem";
import { TicketModal } from "../../../components/TicketModal/TicketModal";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { IPaginatedRequest } from "../../../types/clients";
import { EdgeTicketsListContainer } from "./EdgeTicketsListContainer";
import { MigrateTicketModal } from "./types";

const EdgeMigrateModalSelect: React.FC<PageProps> = ({
  edge,
  setCurrentPage,
  setSelectedTickets,
  selectedTickets
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [textQuery, setTextQuery] = useState<string>("");
  const [ticketToPreview, setTicketToPreview] = useState<number>();

  const { debouncedValue: debouncedTextQuery } = useDebounce(textQuery, 300);

  const [pageArgs, setPageArgs] = useState<IPaginatedRequest<{}>>({
    page: 1,
    perPage: 6,
    sortBy: toSortString([{ id: "createdOn", desc: true }])
  });

  const ticketArgs = {
    edge: edge.id,
    textQuery: debouncedTextQuery,
    ...pageArgs
  };
  const edgeTicketsQuery = useQuery(
    [WebCacheKey.EDGE_TICKETS, ticketArgs],
    () => fetchTicketsForEdge(ticketArgs)
  );

  React.useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  // Reset page to 1 when query changes.
  React.useEffect(() => {
    setPageArgs((old) => ({ ...old, page: 1 }));
  }, [debouncedTextQuery]);

  const added = (ticket: ITicketListing): boolean =>
    selectedTickets.some((t) => t.id === ticket.id);

  return (
    <>
      <p className="disclaimer">
        Migrating moves one or more examples from this edge to another edge.
      </p>
      <div className="migrate-tickets-content">
        <h5>Select Examples</h5>
        <div className="tickets">
          <TextInput
            autoFocus
            ref={inputRef}
            className="ticket-search"
            name={`ticket-migrate-search-${new Date().valueOf().toString()}`}
            placeholder="Search examples by text or ID"
            value={textQuery}
            onChange={(e) => setTextQuery(e.currentTarget.value)}
          />
          <WithLoading
            isLoading={edgeTicketsQuery.isLoading}
            fallback={<Spinner />}
            type="overlay"
          >
            {!!edgeTicketsQuery.data && (
              <PaginationAnalytics data={edgeTicketsQuery.data} />
            )}
            {!!edgeTicketsQuery.data?.items.length ? (
              <EdgeTicketsListContainer>
                {edgeTicketsQuery.data?.items.map((ticket, idx) => (
                  <TicketItem
                    className={classNames({ added: added(ticket) })}
                    key={idx}
                    ticket={ticket}
                    actions={
                      added(ticket) ? (
                        <div>
                          <div className="added-label">Added</div>
                          <Icons.Times
                            data-testid={`delete-result-${ticket.id}`}
                            onClick={(e) =>
                              setSelectedTickets((tickets) =>
                                tickets.filter((t) => t.id !== ticket.id)
                              )
                            }
                          />
                        </div>
                      ) : (
                        <Icons.Plus
                          data-testid={`add-result-${ticket.id}`}
                          onClick={(e) =>
                            setSelectedTickets((tickets) => [
                              ...tickets,
                              ticket
                            ])
                          }
                        />
                      )
                    }
                    onSelect={(ticket) => setTicketToPreview(ticket.id)}
                  />
                ))}
              </EdgeTicketsListContainer>
            ) : (
              <>
                {!!textQuery ? (
                  <NoData message={NoDataMessage.SEARCH_LINKED_TICKETS} />
                ) : (
                  <NoData message={NoDataMessage.LINKED_TICKETS} />
                )}
              </>
            )}
          </WithLoading>
          {edgeTicketsQuery.data && (
            <PaginationBar
              page={edgeTicketsQuery.data.page}
              totalPages={edgeTicketsQuery.data.totalPages}
              onChange={(updates) =>
                setPageArgs((old) => ({ ...old, ...updates }))
              }
            />
          )}
        </div>
      </div>
      <ModalFooter>
        <div className="d-flex justify-content-between align-items-center">
          {!!selectedTickets.length ? (
            <>
              <div>
                {`${selectedTickets.length} ${pluralize(
                  "Ticket",
                  selectedTickets.length
                )} selected`}
              </div>
              <Button
                color="primary"
                onClick={() => setCurrentPage(MigrateTicketModal.TWO)}
              >
                Continue
              </Button>
            </>
          ) : (
            <>
              <span className="text-muted">No examples selected</span>
              <span className="text-muted">Click + to select examples</span>
            </>
          )}
        </div>
      </ModalFooter>
      <TicketModal
        ticketId={ticketToPreview}
        isOpen={!!ticketToPreview}
        onClose={() => setTicketToPreview(undefined)}
        isDisabled
      />
    </>
  );
};

interface PageProps {
  edge: IEdgeInfo;
  selectedTickets: ITicketListing[];
  setSelectedTickets: React.Dispatch<React.SetStateAction<ITicketListing[]>>;
  setCurrentPage: (page: MigrateTicketModal) => void;
}

export { EdgeMigrateModalSelect };
