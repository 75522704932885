import { usePrevious } from "@aptedge/lib-ui/src/hooks/usePrevious";
import { isEqual } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { getCssGridMinMax } from "./utils";

type StringKey<T> = keyof T extends string ? keyof T : never;

type ColumnAlign = "top" | "middle" | "bottom" | "baseline";
type ColumnJustify = "left" | "right" | "center";

interface CellComponentProps<T> {
  id: StringKey<T>;
  data: T;
  col: Column<T>;
}

interface Column<T> {
  id: StringKey<T>;
  label?: string;
  component?: React.ComponentType<CellComponentProps<T>>;
  sortKey?: string;
  minMax?: string;
  align?: ColumnAlign;
  justify?: ColumnJustify;
  isSortable?: boolean;
}

interface UseTableOptions<T> {
  data?: T[];
  dummyRow?: T;
  isLoading?: boolean;
  isSelectable?: boolean;
  areRowsEqual: (a: T, b: T) => boolean;
  columns: Column<T>[];
  hiddenColumns?: StringKey<T>[];
}

interface UseTableResult<T> {
  rows: T[];
  displayedColumns: Column<T>[];
  setHiddenColumns: React.Dispatch<React.SetStateAction<StringKey<T>[]>>;
  selected: T[];
  isRowSelected: (row: T) => boolean;
  allRowsAreSelected: boolean;
  toggleRow: (row: T) => void;
  toggleAllRows: () => void;
  clearSelectedRows: () => void;
  getTableProps: () => React.DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  >;
}

function useTable<T>(opts: UseTableOptions<T>): UseTableResult<T> {
  const {
    data: rows = [],
    isLoading = false,
    isSelectable,
    areRowsEqual,
    dummyRow,
    columns,
    hiddenColumns = []
  } = opts;

  const [selected, setSelected] = useState<T[]>([]);
  const [hidden, setHiddenColumns] = useState<StringKey<T>[]>(hiddenColumns);
  const previousRows = usePrevious(rows);

  const isRowSelected = useCallback(
    (row: T) =>
      !!selected.find(
        (r) => !!areRowsEqual && !!isSelectable && areRowsEqual(r, row)
      ),
    [areRowsEqual, isSelectable, selected]
  );

  const allRowsAreSelected = useMemo(
    () => !!rows.length && !!selected.length && rows.length === selected.length,
    [rows, selected]
  );

  const clearSelectedRows = useCallback(() => setSelected([]), []);

  const toggleRow = useCallback(
    (row: T) => {
      if (!isSelectable || !areRowsEqual) return;

      setSelected((selected) => {
        const match = selected.findIndex((r) => areRowsEqual(r, row));

        if (match > -1) {
          return selected.filter((r) => !areRowsEqual(r, row));
        }

        return [...selected, row];
      });
    },
    [areRowsEqual, isSelectable]
  );

  const toggleAllRows = useCallback(() => {
    if (!isSelectable) return;

    if (selected.length !== rows.length) {
      setSelected(rows);
    } else {
      setSelected([]);
    }
  }, [rows, isSelectable, selected.length]);

  const displayedColumns = useMemo(
    () => columns.filter((c) => !hidden.includes(c.id as StringKey<T>)),
    [columns, hidden]
  );

  const gridTemplateColumns: string = useMemo(() => {
    const colWidths = columns.map((c) => c.minMax || null);
    const gridCol = isSelectable
      ? [getCssGridMinMax(50, 50), ...colWidths]
      : colWidths;

    return gridCol
      .map((w) => {
        if (w) {
          return w;
        } else {
          return `minmax(50px, auto)`;
        }
      })
      .join("");
  }, [columns, isSelectable]);

  // Either display the rows or show n number of dummy rows for the loading animation.
  const displayedRows =
    isLoading && dummyRow
      ? Array(previousRows?.length || 5).fill(dummyRow)
      : rows;

  useEffect(() => {
    if (!isEqual(rows, previousRows)) {
      setSelected((prev) =>
        prev.filter((p) => rows.some((r) => areRowsEqual(r, p)))
      );
    }
  }, [rows, previousRows, selected, areRowsEqual]);

  return {
    rows: displayedRows,
    displayedColumns,
    setHiddenColumns,
    selected,
    isRowSelected,
    allRowsAreSelected,
    clearSelectedRows,
    toggleRow,
    toggleAllRows,
    getTableProps: () => ({ style: { gridTemplateColumns } })
  };
}

export { useTable };
export type { Column, CellComponentProps, StringKey, UseTableResult };
