import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import isEqual from "lodash/isEqual";
import React, {
  ChangeEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { Manager, Popper, Reference } from "react-popper";
import { useArrowDown } from "../../hooks/useArrowDown";
import { useEventListener } from "../../hooks/useEventListener";
import { useOnMouseDownOutside } from "../../hooks/useOnMouseDownOutside";
import { Keys } from "../../styles/keys";
import { Icon } from "../Icon/Icon";
import { DEFAULT_PREVENT_OVERFLOW_MODIFIER } from "../Popper/popper";
import { Spinner, SpinnerSize } from "../Spinner/Spinner";
import { TextInput } from "../TextInput/TextInput";
import "./Select.scss";

type ClearRow = {
  isClearRow: true;
};

interface Props<T> {
  id?: string;
  value?: T;
  data: Array<T>;
  label?: string;
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSearchable?: boolean;
  isClearable?: boolean;
  clearRowLabel?: string;
  showArrow?: boolean;
  autoFocus?: boolean;
  inline?: boolean;
  placeholder?: string;
  getOptionLabel: (item: T) => string;
  getDisplayValue?: (item: T) => string;
  onChange?: (selected: T | null) => void;
  onInputChange?: (text?: string) => void;
  disabledPlaceholder?: string;
  error?: string;
  noDataPlaceholder?: ReactElement;
}

function Select<T>(props: Props<T>): ReactElement {
  const {
    id,
    data,
    label,
    error,
    isLoading,
    isDisabled,
    isSearchable,
    isClearable,
    inline,
    getOptionLabel,
    getDisplayValue = getOptionLabel,
    value,
    placeholder = "Select",
    className,
    onChange,
    onInputChange,
    showArrow = true,
    clearRowLabel = placeholder,
    autoFocus,
    disabledPlaceholder,
    noDataPlaceholder
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const listBoxRef = useRef<HTMLDivElement>(null);

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [textInput, setTextInput] = useState<string>();
  const [internalValue, setInternalValue] = useState<T | null>(value || null);

  const isSelected = (item: T | ClearRow): boolean =>
    (!!(item as ClearRow).isClearRow && !internalValue) ||
    (!!internalValue && isEqual(item, internalValue));

  const getLabel = (item: T | ClearRow): string => {
    if ((item as ClearRow).isClearRow) {
      return clearRowLabel;
    } else {
      return getOptionLabel(item as T);
    }
  };

  const filteredData = textInput
    ? data.filter((item) =>
        getLabel(item).toLowerCase().includes(textInput.toLowerCase())
      )
    : data;

  const displayedData: Array<T | ClearRow> =
    isClearable && !!data.length
      ? [{ isClearRow: true }, ...filteredData]
      : filteredData;

  const displayValue =
    isDisabled && disabledPlaceholder
      ? disabledPlaceholder
      : internalValue
      ? getDisplayValue(internalValue)
      : placeholder || "Select";

  const setInternalTextInputAndEmit = (val?: string): void => {
    setTextInput(val);
    if (onInputChange) onInputChange(val);
  };

  const setInternalValueAndEmit = (val: T | null): void => {
    const idx = data.findIndex((item) => isEqual(val, item));
    setInternalValue(val);
    setInternalTextInputAndEmit(undefined);
    setPopupOpen(false);
    setHighlighted(idx);
    if (onChange) onChange(val);
  };

  const togglePopup = (): void => {
    setInternalTextInputAndEmit(undefined);
    if (!isDisabled) setPopupOpen(!popupOpen);
  };

  const handleOptionClick = (item: T | ClearRow): void => {
    if ((item as ClearRow).isClearRow) {
      setInternalValueAndEmit(null);
    } else {
      setInternalValueAndEmit(item as T);
    }
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setInternalTextInputAndEmit(e.target.value);
  };

  // Reset if error, or value changes from parent.
  useEffect(() => {
    setInternalValue(value || null);
  }, [value, error]);

  // Set focus on first render.
  useEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();

      if (!value) {
        setPopupOpen(true);
      }
    }
  }, [inputRef, value, autoFocus]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ([Keys.ESCAPE, Keys.TAB].includes(e.key as Keys) && popupOpen) {
        setPopupOpen(false);
      }
    },
    [popupOpen]
  );

  useEventListener("keydown", onKeyDown);

  useOnMouseDownOutside(containerRef, () => setPopupOpen(false));

  const { setHighlighted, getListItemProps, getListBoxProps } = useArrowDown({
    ref: containerRef,
    listBoxRef,
    items: displayedData,
    onKeyDown: (idx, key) => {
      if (key === Keys.ENTER && popupOpen) {
        const match = displayedData[idx];
        if (match) {
          if ((match as ClearRow).isClearRow) {
            setInternalValueAndEmit(null);
          } else {
            setInternalValueAndEmit(match as T);
          }
        }
      }

      if (!popupOpen) togglePopup();
    },
    onMouseClick: (idx: number) => {
      handleOptionClick(displayedData[idx]);
    }
  });

  const indicators = (
    <>
      {isLoading && !isDisabled && <Spinner size={SpinnerSize.SMALL} />}
      {showArrow && (
        <div className="icon">
          <Icon icon={faChevronDown} />
        </div>
      )}
    </>
  );

  return (
    <div
      id={id}
      className={classNames("select", className)}
      ref={containerRef}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={togglePopup}>
              {popupOpen && !isDisabled && isSearchable ? (
                <TextInput
                  ref={inputRef}
                  label={label}
                  inline={inline}
                  value={textInput}
                  error={error}
                  placeholder={displayValue}
                  onChange={handleInputChange}
                  indicators={indicators}
                  autoFocus
                />
              ) : (
                <TextInput
                  ref={inputRef}
                  label={label}
                  inline={inline}
                  value={displayValue}
                  error={error}
                  isDisabled={isDisabled}
                  placeholder={placeholder}
                  indicators={indicators}
                  readOnly
                />
              )}
            </div>
          )}
        </Reference>
        {popupOpen && (
          <Popper
            placement="bottom-end"
            modifiers={[DEFAULT_PREVENT_OVERFLOW_MODIFIER]}
          >
            {({ ref, style, placement }) => (
              <div className="popper">
                <div
                  className="select-content"
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  {isLoading ? (
                    <div className="option loading">Loading...</div>
                  ) : (
                    <>
                      <div ref={listBoxRef} {...getListBoxProps()}>
                        {displayedData.map((item, idx) => {
                          return (
                            <div
                              key={idx}
                              {...getListItemProps(
                                idx,
                                classNames({
                                  selected: isSelected(item),
                                  clear: !!(item as ClearRow).isClearRow
                                })
                              )}
                            >
                              <span>{getLabel(item)}</span>
                            </div>
                          );
                        })}
                      </div>
                      {!data.length &&
                      !!noDataPlaceholder &&
                      textInput === undefined ? (
                        <div>{noDataPlaceholder}</div>
                      ) : (
                        !displayedData.length && (
                          <div className="no-match">No matching items</div>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  );
}

export { Select };
