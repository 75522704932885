import {
  AptAnswersFilterRule,
  AptAnswersFilterType
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

interface CreateFilterArgs {
  filterName: string;
  filterType: AptAnswersFilterType;
  filterRules: AptAnswersFilterRule[];
}

interface CreateFilterResponse {
  filterId: string;
}

async function createFilter({
  filterName,
  filterType,
  filterRules
}: CreateFilterArgs): Promise<CreateFilterResponse> {
  const url = `${getBackendApiUrl()}/apt-answer-filter/`;
  const options = post({ name: filterName, filterType, filterRules });
  return request(url, options);
}

export { createFilter };
