import { faEye } from "@fortawesome/free-regular-svg-icons";
import {
  faBold,
  faFileAlt,
  faImage,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faQuoteLeft,
  faStrikethrough
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { EditorUtils } from "../MarkdownEditor/EditorUtils";
import { MarkdownBlockType, MarkdownInlineType } from "../MarkdownEditor/types";
import { MarkdownTextDropdown } from "./MarkdownTextDropdown";
import { ToolbarButton } from "./ToolbarButton";
import "./MarkdownToolbar.scss";

interface Props {
  editor: CodeMirror.Editor;
  previewIsActive?: boolean;
  onTogglePreview?: () => void;
  templateDisabled?: boolean;
  onClickTemplate?: () => void;
}

const Divider = (): React.ReactElement => (
  <span className="border-right mr-1 ml-1 mr-xl-2 ml-xl-2 d-none d-lg-block align-self-stretch" />
);

const MarkdownToolbar: React.FC<Props> = ({
  editor,
  previewIsActive,
  onTogglePreview,
  templateDisabled = true,
  onClickTemplate
}) => {
  const squashEvent = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <div className="markdown-toolbar d-flex flex-wrap align-items-baseline">
      <span className="d-none d-lg-inline" onMouseDown={squashEvent}>
        <MarkdownTextDropdown editor={editor} />
      </span>
      <Divider />
      <ToolbarButton
        icon={faBold}
        disabled={previewIsActive}
        active={EditorUtils.isInlineStyleActive(
          editor,
          MarkdownInlineType.BOLD
        )}
        onMouseDown={() =>
          EditorUtils.toggleInlineStyle(editor, MarkdownInlineType.BOLD)
        }
      />
      <ToolbarButton
        icon={faItalic}
        disabled={previewIsActive}
        active={EditorUtils.isInlineStyleActive(
          editor,
          MarkdownInlineType.ITALIC
        )}
        onMouseDown={() =>
          EditorUtils.toggleInlineStyle(editor, MarkdownInlineType.ITALIC)
        }
      />
      <ToolbarButton
        icon={faStrikethrough}
        disabled={previewIsActive}
        active={EditorUtils.isInlineStyleActive(
          editor,
          MarkdownInlineType.STRIKETHROUGH
        )}
        onMouseDown={() =>
          EditorUtils.toggleInlineStyle(
            editor,
            MarkdownInlineType.STRIKETHROUGH
          )
        }
      />
      <Divider />
      <ToolbarButton
        icon={faListUl}
        disabled={previewIsActive}
        active={EditorUtils.isBlockActive(
          editor,
          MarkdownBlockType.UNORDERED_LIST
        )}
        onMouseDown={() =>
          EditorUtils.toggleBlock(editor, MarkdownBlockType.UNORDERED_LIST)
        }
      />
      <ToolbarButton
        icon={faListOl}
        disabled={previewIsActive}
        active={EditorUtils.isBlockActive(
          editor,
          MarkdownBlockType.ORDERED_LIST
        )}
        onMouseDown={() =>
          EditorUtils.toggleBlock(editor, MarkdownBlockType.ORDERED_LIST)
        }
      />
      <Divider />
      <ToolbarButton
        icon={faQuoteLeft}
        disabled={previewIsActive}
        active={EditorUtils.isBlockActive(editor, MarkdownBlockType.BLOCKQUOTE)}
        onMouseDown={() =>
          EditorUtils.toggleBlock(editor, MarkdownBlockType.BLOCKQUOTE)
        }
      />

      <Divider />
      <ToolbarButton
        icon={faLink}
        disabled={previewIsActive}
        active={EditorUtils.isInlineStyleActive(
          editor,
          MarkdownInlineType.LINK
        )}
        onMouseDown={() =>
          EditorUtils.toggleInlineStyle(editor, MarkdownInlineType.LINK)
        }
      />
      <ToolbarButton
        icon={faImage}
        disabled={previewIsActive}
        active={EditorUtils.isBlockActive(editor, MarkdownBlockType.IMAGE)}
        onMouseDown={() =>
          EditorUtils.toggleBlock(editor, MarkdownBlockType.IMAGE)
        }
      />
      {onClickTemplate && (
        <ToolbarButton
          tooltip="Use template text"
          icon={faFileAlt}
          disabled={templateDisabled}
          active={false}
          onMouseDown={onClickTemplate}
        />
      )}
      {onTogglePreview && (
        <>
          <Divider />
          <ToolbarButton
            icon={faEye}
            active={!!previewIsActive}
            onMouseDown={onTogglePreview}
          />
        </>
      )}
    </div>
  );
};

export { MarkdownToolbar };
