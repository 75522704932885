import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import ImageIcon from "@aptedge/lib-ui/src/components/Icon/ImageIcon";
import SkeletonLoader from "@aptedge/lib-ui/src/components/SkeletonLoader/SkeletonLoader";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useSearchFilterData } from "@aptedge/lib-ui/src/features/SearchFilters/useSearchFilter";
import { useSetParamsWithAnswerId } from "@aptedge/lib-ui/src/hooks/useSetParamsWithAnswerId";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateFeedbackSubmittedIds,
  updatePage,
  updateSearchCardVisibility,
  updateSearchFilter,
  updateSearchFilterSubType
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { SearchFilterDropdown } from "@aptedge/lib-ui/src/services/search/searchFilterService";
import {
  FeedbackIds,
  ISearchFilter,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { dataLayerPush, GTM_EVENTS } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import React from "react";
import FilterDropdown from "./FilterDropDown/FilterDropDown";
import "./GlobalSearchPage.scss";

type SearchFiltersFn = (
  filter: ISearchFilter,
  sourceType: string,
  isFilterDisabled?: boolean
) => void;
interface IProps {
  onSearchFilterClick: SearchFiltersFn;
}

const trigerSearchFilterEvent = (
  filter: ISearchFilter,
  sourceType: string
): void => {
  dataLayerPush({
    event: GTM_EVENTS.GTM_SEARCH_RESULT_FILTER,
    data: {
      search_result_type: filter,
      integration_type: sourceType
    }
  });
};

const RenderAllFilter: React.FC<IProps> = ({ onSearchFilterClick }) => {
  const { searchFilter } = useAppSelector((state) => state.search);
  const isSelectedFilter = searchFilter === ISearchFilter.ALL;

  return (
    <div
      className={classNames("global-search-filter-wrapper-all", {
        "global-search-filter-selected": !!isSelectedFilter
      })}
      onClick={() => onSearchFilterClick(ISearchFilter.ALL, "")}
    >
      <Icons.Search
        className={classNames({
          "global-search-filter-selected-icon": isSelectedFilter,
          "global-search-filter-icon": !isSelectedFilter
        })}
      />
      <span className="txt">All</span>
    </div>
  );
};

const RenderFilter: React.FC<IProps> = ({ onSearchFilterClick }) => {
  const { searchFilter } = useAppSelector((state) => state.search);
  const { searchFilterList, isLoading } = useSearchFilterData();
  if (isLoading) {
    return (
      <SkeletonLoader
        lines={5}
        containerClassName="skeleton-loaders"
        loaderClassName="loader"
      />
    );
  }

  return (
    <>
      {searchFilterList.map((itemFilter) => {
        const {
          label: title,
          filter_key: key,
          filters: dropDownMenu,
          icon
        } = itemFilter;

        const IconRight = AeIcons.ChevronDown;
        const isRightIconVisible =
          Array.isArray(dropDownMenu) && dropDownMenu.length > 0;

        const isSelectedFilter = searchFilter === key;
        const isFilterDisabled =
          Array.isArray(dropDownMenu) && !isRightIconVisible;

        return (
          <div
            key={key}
            className={classNames("global-search-filter-wrapper", {
              "global-search-filter-selected": isSelectedFilter
            })}
          >
            <Tooltip
              disabled={!isFilterDisabled}
              content={`You don't have any integrations setup for ${title}`}
            >
              <div
                onClick={() => onSearchFilterClick(key, "", isFilterDisabled)}
                className={classNames("global-search-filter-item", {
                  disable: isFilterDisabled
                })}
              >
                {isRightIconVisible ? (
                  <FilterDropdown
                    dropDownMenu={dropDownMenu as SearchFilterDropdown[]} // the current typescript version has a weird bug its not able to infer type based on the condition
                    onFilterClick={() => onSearchFilterClick(key, "")}
                    onSubFilterClick={(index) =>
                      onSearchFilterClick(
                        key,
                        (dropDownMenu as SearchFilterDropdown[])[index]
                          ?.filter_key
                      )
                    }
                  >
                    <ImageIcon
                      src={icon}
                      className={classNames({
                        "global-search-filter-selected-icon": isSelectedFilter
                      })}
                    />
                    <span className="txt">{title ?? ""}</span>
                    <IconRight
                      className={classNames({
                        "global-search-filter-selected-icon": isRightIconVisible
                      })}
                    />
                  </FilterDropdown>
                ) : (
                  <>
                    <ImageIcon
                      src={icon}
                      className={classNames({
                        "global-search-filter-selected-icon": isSelectedFilter
                      })}
                    />
                    <span className="txt">{title ?? ""}</span>
                  </>
                )}
              </div>
            </Tooltip>
          </div>
        );
      })}
    </>
  );
};

const GlobalSearchFilter: React.FC<{}> = () => {
  const { searchFilter, searchQuery } = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();
  const { removeQueryParams, setSearchQueryParam } = useSetParamsWithAnswerId(
    searchQuery
  );
  const { flags } = useFlags();

  const onSearchFilterClick: SearchFiltersFn = (
    filter,
    sourceType,
    isFilterDisabled
  ): void => {
    if (isFilterDisabled) return;
    trigerSearchFilterEvent(filter, sourceType);
    if (searchQuery) {
      setSearchQueryParam(QUERY_PARAMS.FILTER, filter);
    }
    if (!Boolean(sourceType) && filter !== searchFilter) {
      dispatch(updateSearchFilterSubType(""));
    }
    dispatch(updateSearchFilter(filter));
    dispatch(updatePage(1));
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
    dispatch(updateSearchCardVisibility(false));
    dispatch(updateFeedbackSubmittedIds({} as FeedbackIds));
  };

  return (
    <div
      className={classNames("global-search-filter-container", {
        "global-search-filter-container-v1": !flags.answerGptUiV2
      })}
    >
      <RenderAllFilter onSearchFilterClick={onSearchFilterClick} />
      <div className="vertical-seprator"></div>
      <RenderFilter onSearchFilterClick={onSearchFilterClick} />
    </div>
  );
};

export default GlobalSearchFilter;
