/* istanbul ignore file */

export enum Routes {
  HOME = "/",
  LOGIN = "/login",
  LOGOUT = "/logout",
  RECOVERY = "/recovery",
  RESET = "/reset",
  WELCOME = "/welcome",
  FEED = "/feed",
  EDGES = "/edges",
  EDGES_EDGE = "/edges/:edgeId",
  ANALYTICS = "/analytics",
  ANALYTICS_OVERVIEW = "/analytics/overview",
  ANALYTICS_PRODUCT = "/analytics/products/:productId",
  ANALYTICS_PRODUCT_LIST = "/analytics/products",
  ANALYTICS_FEATURE = "/analytics/features/:featureId",
  ANALYTICS_THEME = "/analytics/themes/:themeId",
  ANALYTICS_THEME_LIST = "/analytics/themes",
  ANALYTICS_CUSTOMER = "/analytics/customers/:customerId",
  ANALYTICS_CUSTOMER_LIST = "/analytics/customers",
  EXPLORER = "/explorer",
  EXPLORER_CREATED_TICKETS = "/explorer/created-tickets",
  EXPLORER_OPEN_TICKETS = "/explorer/open-tickets",
  EXPLORER_CREATED_EDGES = "/explorer/created-edges",
  ARTICLES = "/articles",
  ARTICLES_PENDING = "/articles/pending",
  ARTICLES_REVIEW = "/articles/review",
  SETTINGS = "/settings",
  SETTINGS_APPS = "/settings/apps",
  SETTINGS_MACHINE_LEARNING = "/settings/machine-learning",
  SETTINGS_WORKFLOW = "/settings/workflow",
  SETTINGS_STATUS = "/settings/status",
  USER_SETTINGS = "/user-settings",
  TICKETS = "/tickets",
  TICKET = "/tickets/:ticketId",
  GLOBAL_SEARCH = "/search",
  GLOBAL_OAUTH = "/global-oauth/:provider",
  ADMIN_DASHBOARD = "/settings/admin-dashboard",
  CSS_ANALYTICS = "/settings/css-admin-dashboard",
  CSS_CONFIG = "/settings/css-config",
  FILTERS_CONFIG = "/settings/filters",
  DOCUMENT_FILES = "/edges/:sourcetype/files/:fileId"
}
