import classNames from "classnames";
import React from "react";
import { findChildrenByType } from "../../utils/reactUtils";
import { Button, ButtonColor, ButtonProps } from "../Button/Button";
import "./ButtonGroup.scss";

const ButtonGroup: React.FunctionComponent<Props> = ({
  children,
  disabled
}) => {
  const color: ButtonColor = "secondary";
  const buttons = React.Children.toArray(findChildrenByType(children, Button));

  const activeIndices = buttons.reduce((memo: Array<number>, b, idx) => {
    if (React.isValidElement(b) && !!b.props.active) {
      memo.push(idx);
    }

    return memo;
  }, []);

  return (
    <div className="button-group">
      {React.Children.map(
        buttons,
        (button, idx): React.ReactNode => {
          return (
            React.isValidElement(button) &&
            React.cloneElement<ButtonProps>(button, {
              color,
              disabled,
              className: classNames(button.props.className, {
                right:
                  activeIndices.includes(idx) && activeIndices.includes(idx + 1)
              })
            })
          );
        }
      )}
    </div>
  );
};

interface Props {
  disabled?: boolean;
}

export { ButtonGroup };
