import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateSearchSuggestions } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { searchSuggestions } from "@aptedge/lib-ui/src/services/search/searchSuggestion";
import { AppType, ISearchFilter } from "@aptedge/lib-ui/src/types/entities";
import { getTrimmedString } from "@aptedge/lib-ui/src/utils/utils";
import { UseQueryResult, useQuery } from "react-query";
import { WebCacheKey } from "../../../../../app-web/src/clients/cache";

interface IUseSearchSuggestion {
  searchSuggestionsQuery: UseQueryResult<unknown, unknown>;
}

const useSearchSuggestion = (
  searchQuery: string,
  type: ISearchFilter,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suggestions?: any
): IUseSearchSuggestion => {
  const { debouncedValue } = useDebounce(getTrimmedString(searchQuery), 300);
  const { appType } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const isSearchEnabled = (): boolean => {
    return Boolean(debouncedValue || (!!debouncedValue && !!type));
  };

  const searchSuggestionsQuery = useQuery(
    [WebCacheKey.SEARCH_SUGGESTIONS, debouncedValue, type],
    () => {
      if (appType === AppType.SUPPORT_APP) {
        return suggestions.search(debouncedValue, type);
      }
      return searchSuggestions({ q: debouncedValue }, type);
    },
    {
      enabled: isSearchEnabled(),
      onSuccess: (data) => dispatch(updateSearchSuggestions(data?.suggestions))
    }
  );
  return { searchSuggestionsQuery };
};

export default useSearchSuggestion;
