import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  ArticleEditType,
  ArticleStatus,
  GeneratedKnowledgeResult,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthContext";

type ArticleItem = {
  isTitleEdit: boolean;
  isBodyEdit: boolean;
  title: string;
  body: string;
  currentUserId: number;
  currentUserRole: UserRole;
  handleEditArticle: (editType: ArticleEditType) => void;
  handleTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCancelEdit: (editType: ArticleEditType) => void;
  handleBodyChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleSaveEdit: (editType: ArticleEditType) => void;
};

function useArticleItem(article: GeneratedKnowledgeResult): ArticleItem {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [originalTitle, setOriginalTitle] = useState("");
  const [originalBody, setOriginalBody] = useState("");
  const [isTitleEdit, setIsTitleEdit] = useState(false);
  const [isBodyEdit, setIsBodyEdit] = useState(false);
  const { user: currentUser } = useAuth();

  const currentUserId = currentUser?.id || 0;

  const { currentUserRole } = useAppSelector((state) => state.app);

  const handleEditArticle = (editType: ArticleEditType): void => {
    if (
      article &&
      article.status !== ArticleStatus.DRAFT &&
      currentUserRole === UserRole.AGENT
    )
      return;
    switch (editType) {
      case ArticleEditType.TITLE:
        setIsTitleEdit(true);
        break;
      case ArticleEditType.BODY:
        setIsBodyEdit(true);
        break;
    }
  };

  const handleSaveEdit = (editType: ArticleEditType): void => {
    switch (editType) {
      case ArticleEditType.TITLE:
        setOriginalTitle(title);
        setIsTitleEdit(false);
        break;
      case ArticleEditType.BODY:
        setOriginalBody(body);
        setIsBodyEdit(false);
        break;
    }
  };

  const handleCancelEdit = (editType: ArticleEditType): void => {
    switch (editType) {
      case ArticleEditType.TITLE:
        if (article) {
          setTitle(originalTitle);
        }
        setIsTitleEdit(false);
        break;
      case ArticleEditType.BODY:
        if (article) {
          setBody(originalBody);
        }
        setIsBodyEdit(false);
        break;
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value);
  };

  const handleBodyChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setBody(e.target.value);
  };

  useEffect(() => {
    if (article) {
      setBody(article.body.body);
      setTitle(article.title);
      setOriginalTitle(article.title);
      setOriginalBody(article.body.body);
    }
  }, [article]);

  return {
    isTitleEdit,
    isBodyEdit,
    title,
    body,
    currentUserId,
    currentUserRole,
    handleEditArticle,
    handleTitleChange,
    handleCancelEdit,
    handleBodyChange,
    handleSaveEdit
  };
}

export { useArticleItem };
