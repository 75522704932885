import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import SearchSkeletonLoader from "@aptedge/lib-ui/src/components/SearchSkeletonLoader/SearchSkeletonLoader";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { FC } from "react";
import { PaginationAnalytics } from "../../PaginationAnalytics/PaginationAnalytics";
import WithLoading from "../../WithLoading/WithLoading";
import { GlobalSearchItem } from "../GlobalSearchItem/GlobalSearchItem";
import "./GlobalSearchList.scss";
import GlobalSearchSort from "../GlobalSearchSort/GlobalSearchSort";
import { IUseArrowNavigationSL } from "./hooks/useArrowNavigationSL";

const PER_PAGE = 10;

const shouldFirstCardHighlighted = (
  resultId: string | number,
  idx: number,
  highlighted: number,
  selectedSearchResult: ICompositeResult
): boolean => {
  return highlighted === idx || selectedSearchResult?.id === resultId;
};

interface Props {
  arrowNavigation: Omit<IUseArrowNavigationSL, "searchContainerRef">;
}

const GlobalSearchList: FC<Props> = ({ arrowNavigation }) => {
  const {
    getListBoxProps,
    getListItemProps,
    listBoxRef,
    highlighted,
    recentSearches
  } = arrowNavigation;

  const {
    searchQuery,
    searchResults,
    page,
    selectedSearchResult,
    searchResultsInfo,
    isSearchLoading,
    answerId
  } = useAppSelector((state) => state.search);

  const { flags } = useFlags();

  const total = searchResultsInfo?.total ?? 0;

  const paginationAnalyticsEnabled = (!!total && total > 0) ?? 0;
  const totalPages = searchResultsInfo?.totalPages ?? 1;

  return (
    <div
      ref={listBoxRef}
      id="global-search-results"
      {...getListBoxProps()}
      className={classNames("global-search-results", {
        "mt-3": !flags.answerGptUiV2 && (!searchQuery || !answerId)
      })}
    >
      {searchQuery || Boolean(searchResults.length) ? (
        <>
          {!flags.answerGptUiV2 && (
            <>
              <div className="global-search-container">
                <h5 className="recently-viewed">
                  <AeIcons.Watch /> <span>Search Results</span>
                </h5>
                <div className="list-header">
                  {Boolean(paginationAnalyticsEnabled) && (
                    <PaginationAnalytics
                      data={{
                        page,
                        perPage: PER_PAGE,
                        totalPages,
                        total
                      }}
                      isLoading={isSearchLoading}
                      isApproximate={false}
                    />
                  )}
                  {flags.semanticSearch || (
                    <GlobalSearchSort className="sortDropDown" />
                  )}
                </div>
              </div>
              <hr className="m-0" />
            </>
          )}
          <WithLoading
            isLoading={isSearchLoading}
            fallback={<SearchSkeletonLoader lines={5} flags={flags} />}
          >
            {Boolean(searchResults.length) ? (
              [null, ...searchResults].map((result, idx) => {
                if (result === null) {
                  return <div key={idx} />;
                } else {
                  return (
                    <GlobalSearchItem
                      result={result}
                      key={`${result.id}`}
                      isSelected={shouldFirstCardHighlighted(
                        result.id,
                        idx,
                        highlighted,
                        selectedSearchResult
                      )}
                      {...getListItemProps(idx)}
                    />
                  );
                }
              })
            ) : (
              <NoData message={NoDataMessage.SEARCH_RESULTS} />
            )}
          </WithLoading>
        </>
      ) : (
        <>
          {!flags.answerGptUiV2 && (
            <>
              <div className="global-search-container">
                <h5 className="recently-viewed">
                  <AeIcons.Watch /> <span>Recently Viewed Searches</span>
                </h5>
              </div>
              <hr className="m-0" />
            </>
          )}
          <WithLoading
            isLoading={isSearchLoading}
            fallback={<SearchSkeletonLoader lines={5} flags={flags} />}
          >
            {!!recentSearches?.length ? (
              [null, ...recentSearches]?.map((result, idx) => {
                if (result === null) {
                  return <div key={idx} />;
                } else {
                  return (
                    <GlobalSearchItem
                      result={result}
                      key={`${result.id}`}
                      isSelected={shouldFirstCardHighlighted(
                        result.id,
                        idx,
                        highlighted,
                        selectedSearchResult
                      )}
                      {...getListItemProps(idx)}
                    />
                  );
                }
              })
            ) : (
              <NoData message={NoDataMessage.NO_RECENT_SEARCHRES} />
            )}
          </WithLoading>
        </>
      )}
    </div>
  );
};

export { GlobalSearchList };
