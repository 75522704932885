import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { useAptAnswersFilters } from "../../hooks/useAptAnswersFilters";
import { IntegrationItem } from "./IntegrationItem";
import { mergeFiltersWithIntegrations } from "./utils/mergeFiltersWithIntegrations";

interface AptAnswersFiltersProps {
  filterType: AptAnswersFilterType;
}

const AptAnswersFilters: React.FC<AptAnswersFiltersProps> = ({
  filterType
}) => {
  const { filtersData, integrationsData } = useAptAnswersFilters(filterType);
  const integrations = mergeFiltersWithIntegrations({
    filtersData,
    integrationsData
  });

  return (
    <div>
      {integrations.map((integration, idx) => (
        <IntegrationItem
          key={integration.name}
          filterType={filterType}
          integration={integration}
          isTop={idx === 0}
          isBottom={idx === integrations.length - 1}
        />
      ))}
    </div>
  );
};

export { AptAnswersFilters };
