import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import React, { ReactNode } from "react";
import { Icon } from "../Icon/Icon";
import { Tooltip } from "../Tooltip/Tooltip";
import "./InfoButton.scss";

interface InfoButtonProps {
  info: ReactNode;
}

const InfoButton: React.FC<InfoButtonProps> = (props) => {
  return (
    <span className="info-button">
      <Tooltip triggerType="click" content={props.info}>
        <Icon icon={faInfoCircle} color="var(--gray-200)" size="sm" />
      </Tooltip>
    </span>
  );
};

export { InfoButton };
