import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";

interface Dictionary<T> {
  [index: string]: T;
}
type SimpleTypes = string | number | boolean | null | undefined;
export type ShallowDictionary = Dictionary<SimpleTypes | SimpleTypes[]>;

function toKebab(original: object): ShallowDictionary {
  return Object.entries(original).reduce(
    (memo: ShallowDictionary, [key, value]) => {
      memo[kebabCase(key)] = value;

      return memo;
    },
    {}
  );
}

function toCamel<T extends object>(original: object): T {
  return Object.entries(original).reduce(
    (memo: ShallowDictionary, [key, value]) => {
      memo[camelCase(key)] = value;

      return memo;
    },
    {}
  ) as T;
}

export { toCamel, toKebab };
