import {
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { FilterRulesHeader } from "./FilterRulesHeader";
import { FilterRulesItem } from "./FilterRulesItem";

interface FilterRulesProps {
  rules: AptAnswersFilterRule[];
  fields: string[];
  filterConditions: string[];
  handleRuleUpdate: (o: AptAnswersFilterRuleUpdate) => void;
  handleDeleteRule: (idx: number) => void;
  ruleEditIsDisabled: boolean;
  hasErrors: boolean;
  hasExistingRules: boolean;
}

const FilterRules: React.FC<FilterRulesProps> = ({
  rules,
  fields,
  filterConditions,
  handleRuleUpdate,
  handleDeleteRule,
  ruleEditIsDisabled,
  hasErrors,
  hasExistingRules
}) => {
  return (
    <div>
      {rules.length > 0 ? <FilterRulesHeader /> : null}
      {rules.map((rule, idx) => (
        <FilterRulesItem
          key={idx}
          idx={idx}
          rule={rule}
          fields={fields}
          filterConditions={filterConditions}
          handleRuleUpdate={handleRuleUpdate}
          handleDeleteRule={handleDeleteRule}
          enableDeleteButton={hasExistingRules || rules.length > 1}
          ruleEditIsDisabled={ruleEditIsDisabled}
          isTop={idx === 0}
          isBottom={idx === rules.length - 1}
          hasErrors={hasErrors}
        />
      ))}
    </div>
  );
};

export { FilterRules };
