import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import React, { useState } from "react";
import { useQuery } from "react-query";

type SelectProps = React.ComponentPropsWithoutRef<typeof MultiSelect>;
type SharedProps = Omit<
  SelectProps,
  | "value"
  | "data"
  | "getOptionLabel"
  | "onChange"
  | "compareBy"
  | "renderOption"
>;
type Props<T, V> = SharedProps & {
  value?: V[];
  valueKey: string;
  valueLoader: (values: V[]) => Promise<T[]>;
  optionsKey: string;
  optionsLoader: (textSearch?: string) => Promise<T[]>;
  noDataPlaceholder?: React.ReactElement;
  getOptionAsValue: (item: T) => V;
  getOptionLabel: (item: T) => string;
  renderOption?: (item: T) => React.ReactNode;
  autoComplete?: boolean;
  onChange: (item: V[] | null) => void;
};

function MultiSelectAsync<T extends {}, V>(
  props: Props<T, V>
): React.ReactElement {
  const {
    value,
    valueKey,
    valueLoader,
    optionsKey,
    optionsLoader,
    getOptionAsValue,
    onInputChange,
    onChange,
    ...rest
  } = props;
  const [textSearch, setTextSearch] = useState<string>();
  const { debouncedValue: debouncedSearch } = useDebounce(textSearch, 300);

  const optionsQuery = useQuery([optionsKey, debouncedSearch], () =>
    optionsLoader(debouncedSearch)
  );

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const valueQuery = useQuery([valueKey, value], () => valueLoader(value!), {
    enabled: !!value && !!value.length,
    keepPreviousData: true,
    refetchOnWindowFocus: false
  });

  const isLoading = optionsQuery.isLoading || valueQuery.isLoading;

  const onInputChangeHandler = (text?: string): void => {
    setTextSearch(text);
  };

  const onChangeHandler = (val: T[] | null): void => {
    setTextSearch(undefined);
    onChange(val?.map(getOptionAsValue) || null);
  };

  return (
    <MultiSelect
      value={valueQuery.data}
      data={optionsQuery.data || []}
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      isLoading={isLoading}
      {...rest}
    />
  );
}

export { MultiSelectAsync };
