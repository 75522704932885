/* eslint-disable @typescript-eslint/no-explicit-any */
import cubejs, { Query, ResultSet } from "@cubejs-client/core";
import Cookies from "js-cookie";
import { QueryObserverOptions, useQuery, UseQueryResult } from "react-query";
import { getCubeJsUrl, isLocal } from "../config";
import { ContentType } from "../types/http";

// This can be any random string, since our CubeJS server authenticates through flask using
// cookies and JWT from the browser.
const CUBEJS_TOKEN = "CUBE_JS";

export const cubejsApi = cubejs(CUBEJS_TOKEN, {
  apiUrl: getCubeJsUrl(),
  credentials: isLocal() ? "include" : "same-origin",
  headers: {
    "X-CSRF-Token": Cookies.get("csrf_access_token") || "",
    "Content-Type": ContentType.APPLICATION_JSON
  }
});

function concatErrorMessages(...msgs: string[]): string {
  return msgs.join("\n\n");
}

function _stringify(value: any): string {
  if (typeof value !== "object") {
    return JSON.stringify(value);
  } else if (Array.isArray(value)) {
    return (
      "[" +
      value
        .slice()
        .map((v) => {
          return _stringify(v);
        })
        .sort() // Sort **after** _stringify() so that objects are sorted deterministically
        .join(",") +
      "]"
    );
  } else {
    return (
      "{" +
      Object.keys(value)
        .sort()
        .map((v) => {
          return `"${v}":${_stringify(value[v])}`;
        })
        .join(",") +
      "}"
    );
  }
}

/**
 * Stringifies a cubeJS query into a stable hash.
 */
function stableQueryHash(query: Query): string {
  return _stringify(query);
}

function useCubeQuery<Data>(
  query: Query
): UseQueryResult<ResultSet<Data>, Error>;
function useCubeQuery<Data, MutatedData>(
  query: Query,
  mutationFn: (data: ResultSet<Data>) => MutatedData
): UseQueryResult<MutatedData, Error>;
function useCubeQuery<Data, MutatedData>(
  query: Query,
  mutationFn: (data: ResultSet<Data>) => MutatedData,
  options?: QueryObserverOptions<Data, Error>
): UseQueryResult<MutatedData, Error>;

function useCubeQuery(
  query: any,
  mutationFn = (data: any) => data,
  options: any = {}
): UseQueryResult {
  const key = stableQueryHash(query);

  return useQuery(
    key,
    () =>
      cubejsApi
        .load(query)
        .then(mutationFn)
        .catch((err) => {
          const message = concatErrorMessages(
            err.message,
            "Query: ",
            JSON.stringify(query, null, 2)
          );

          throw new Error(message);
        }),
    options
  );
}

export { useCubeQuery, stableQueryHash };
