import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { useAptAnswersFilters } from "../../hooks/useAptAnswersFilters";
import { AptAnswersErrorMessage } from "./AptAnswersErrorMessage";
import { AptAnswersFilters } from "./AptAnswersFilters";
import styles from "./HiddenFilters.module.scss";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";

const HiddenFilters: React.FC = () => {
  const { filterType } = getAptAnswersFilterInfo(
    AptAnswersFilterType.HIDDEN_FILTER
  );

  const { isError, isLoading, isSuccess } = useAptAnswersFilters(filterType);

  return (
    <div className={styles.container}>
      {isLoading && <Spinner />}
      {isError && (
        <AptAnswersErrorMessage message="There was an error loading Hidden Results." />
      )}
      {isSuccess && <AptAnswersFilters filterType={filterType} />}
    </div>
  );
};

export { HiddenFilters };
