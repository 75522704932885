import { toCamel, toKebab } from "@aptedge/lib-ui/src/utils/case";
import querystring, { ParseOptions, StringifyOptions } from "query-string";
import { QueryParams } from "../../types/util";

const PARSE_OPTIONS: StringifyOptions | ParseOptions = {
  arrayFormat: "comma",
  skipNull: true,
  parseNumbers: true,
  parseBooleans: true
};

/**
 * Converts an object to a query string.
 *
 * NOTE: Nested objects and arrays of objects are not supported.
 */
function toQueryString<T extends object = object>(obj: T): string {
  return querystring.stringify(toKebab(obj), PARSE_OPTIONS);
}

/**
 * Converts a kebab-cased query string to a camel-cased object.
 *
 * @returns QueryParams<T>
 *
 * Note: We have no way of guaranteeing that the query string has all
 * properties of the presumed type. And, we have no way of guaranteeing
 * that array props with a single value are parsed as an array.
 *
 * This therefore returns QueryParams, which is a Partial with
 * Array<T> types cast to Array<T> | T;
 */
function fromQueryString<T extends object>(url: string): QueryParams<T> {
  return toCamel(querystring.parse(url, PARSE_OPTIONS));
}

function boxValueIntoArray<T>(item?: Array<T> | T | null): Array<T> {
  if (!item) {
    return [];
  }

  return Array.isArray(item) ? item : [item];
}

export { toQueryString, fromQueryString, boxValueIntoArray };
