import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useSearchPreview } from "@aptedge/lib-ui/src/hooks/useSearchPreview";
import { SearchFilterDropdown } from "@aptedge/lib-ui/src/services/search/searchFilterService";
import { IArticle, ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import HeaderIcon from "../GlobalSearch/common/HeaderIcon/HeaderIcon";
import SearchCardTimings from "../SearchCardTimings/SearchCardTimings";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";
import "./ArticleSearchResult.scss";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  filterDetails: SearchFilterDropdown[];
  className?: string;
  article: IArticle;
  isSelected?: boolean;
  showActionIcons?: boolean;
}

const DEFAULT_ARTICLE_ICON_URL =
  "https://storage.googleapis.com/aptedge-integrations-assets-customers/globe.svg";

const ArticleSearchResult: React.FC<Props> = ({
  className,
  article,
  isSelected,
  filterDetails,
  showActionIcons,
  ...htmlProps
}) => {
  const { flags } = useFlags();
  const { hasPreview, previewSnippet, previewType } = useSearchPreview({
    ...article,
    type: "article"
  });

  const getArticleIcon = (sourceType: string): string => {
    const [filter] = filterDetails.filter(
      (item) => item?.filter_key === sourceType
    );
    return filter?.icon || DEFAULT_ARTICLE_ICON_URL;
  };

  return (
    <div
      {...htmlProps}
      className={classNames("article-search-result", className, {
        selectable: !!htmlProps.onClick,
        active: isSelected,
        "article-search-result-v1": !flags.answerGptUiV2
      })}
    >
      <div>
        <div className="article-header">
          <HeaderIcon
            icon={getArticleIcon(article?.sourceType)}
            sourceType={article?.sourceType}
            className="mr-3 p-0"
          />
          <span className="subject">
            <SearchResultPreview
              previewSnippet={
                hasPreview && previewType === "title"
                  ? previewSnippet
                  : article.title
              }
            />
          </span>
          {showActionIcons && (
            <div className="article-header-action-icons">
              <SearchResultCardActionIcons
                searchResult={article as ICompositeResult}
              />
            </div>
          )}
        </div>
        {hasPreview && previewType === "body" && (
          <div className="article-description">
            <SearchResultPreview previewSnippet={previewSnippet} />
          </div>
        )}
      </div>
      <SearchCardTimings
        createdOn={article.createdOn}
        updatedOn={article.updatedOn}
      />
    </div>
  );
};

export { ArticleSearchResult };
